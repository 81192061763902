import { IconTypes } from "./components/Icons/Icon";

export enum Locale {
  EN = "en",
  NL = "nl",
  FR = "fr",
  DE = "de",
}

export interface Me {
  name?: string;
  email?: string;
  locale?: Locale;
  workspace_id?: string;
}

export interface User {
  id: string;
  email: string;
  name: string;
  otp_enabled: boolean;
  workspace?: Workspace;
  email_confirmed_at: string;
  created_at: string;
  locale: Locale;
  user_workspace_setting?: UserWorkspaceSetting;
}

export enum UserWorkspaceSettingStatus {
  // i18n.t('UserWorkspaceSettingStatus::active')
  ACTIVE = "active",
  // i18n.t('UserWorkspaceSettingStatus::invited')
  INVITED = "invited",
}

export enum UserWorkspaceSettingRole {
  // i18n.t('UserWorkspaceSettingRole::owner')
  OWNER = "owner",
  // i18n.t('UserWorkspaceSettingRole::manager')
  MANAGER = "manager",
  // i18n.t('UserWorkspaceSettingRole::employee')
  EMPLOYEE = "employee",
}

export enum UserWorkspaceSettingScope {
  DASHBOARD = "dashboard",
  CALENDAR = "calendar",
  TASK = "task",
  CONTACT = "contact",
  DEAL = "deal",
  PROJECT = "project",
  TIME_TRACKING = "time_tracking",
  TIME_TRACKING_EXPORT = "time_tracking:export",
  PROPOSAL = "proposal",
  CONTRACT = "contract",
  INVOICE = "invoice",
  INVOICE_EXPORT = "invoice:export",
  EXPENSE = "expense",
  EXPENSE_EXPORT = "expense:export",
  DOCUMENT = "document",
  DOCUMENT_EXPORT = "document:export",
  BANK = "bank",
  BANK_PAYMENT = "bank:payment",
  ACCOUNTANT = "accountant",
  PRODUCT = "product",
  PRODUCT_EXPORT = "product:export",
  PRODUCT_VIEW_PURCHASE_PRICE = "product:view_purchase_price",
  REPORTING = "reporting",
  SETTING = "setting",
  FILE = "file",
  FORM = "form",
  PLAYBOOK = "playbook",
  SEQUENCE = "sequence",
}

// t('WeekdaySchedule::0')
// t('WeekdaySchedule::1')
// t('WeekdaySchedule::2')
// t('WeekdaySchedule::3')
// t('WeekdaySchedule::4')
// t('WeekdaySchedule::5')
// t('WeekdaySchedule::6')
export interface WeekdaySchedule {
  enabled?: boolean;
  start_time?: string;
  end_time?: string;
  break_enabled?: boolean;
  break_start_time?: string;
  break_end_time?: string;
}

export interface UserWorkspaceSetting {
  id?: string;
  email?: string;
  role?: UserWorkspaceSettingRole;
  scopes?: UserWorkspaceSettingScope[];
  hourly_cost?: number;
  hourly_rate?: number;
  day_rate?: number;
  status?: UserWorkspaceSettingStatus;
  admin?: boolean;
  week_schedule?: WeekdaySchedule[];
  user?: User;
  user_id?: string;
  workspace?: Workspace;
  workspace_id?: string;
}

export interface CurrentUser extends User {}

export enum PeppolWorkspaceStatus {
  APPROVED = "approved",
  PENDING = "pending",
  REJECTED = "rejected",
}

export interface Workspace {
  id: string;
  business_name: string;
  email: string;
  timezone: string;
  street: string;
  receipt_details: string;
  stripe_id: string;
  vip: boolean;
  slug: string;
  city: string;
  province: string;
  zip_code: string;
  country_code: string;
  vat_number: string;
  phone_number: string;
  bic_code?: string;
  account_number: string;
  website: string;
  appointment_url: string;
  description: string;
  did_onboarding: boolean;
  setting: Settings;
  subscription: Subscription;
  card: Card;
  users_count: number;
  business_logo_url: string | null;
  business_logo: string | null;
  app_invite_url: string | null;
  expense_email_address: string;
  bcc_email_address: string;
  peppol_id: string;
  peppol_status: PeppolWorkspaceStatus;
  peppol_enabled: boolean;
}

export enum NumberFormat {
  COMMA_PERIOD = "comma_period",
  PERIOD_COMMA = "period_comma",
  APOSTROPHE_PERIOD = "apostrophe_period",
  SPACE_COMMA = "space_comma",
  SPACE_PERIOD = "space_period",
}

export interface Settings {
  id: string;
  date_format: string;
  time_format: "hh:mm:ss" | "hh mm ss";
  hour_format: "24" | "12";
  start_week_on: "sunday" | "monday";
  default_ledger_item_locale: Locale;
  default_currency: string;
  number_format: NumberFormat;
  quotation_validity_duration: number;
  invoice_payment_duration: number;
  default_hourly_rate: number;
  default_day_rate: number;
  travel_cost: number;

  workspace_id: string;
  weekly_summary_subscription: boolean;
  invoice_viewed_subscription: boolean;
  invoices_due_today_subscription: boolean;
  default_ledger_condition_id: string;
  ledger_item_template_id: string;

  order_forms_enabled: boolean;
  prefix_order_form_number: string;
  order_form_number: string;
  suffix_order_form_number: string;
  next_order_form_number: string;
  order_form_validity_duration: number;

  delivery_notes_enabled: boolean;
  prefix_delivery_note_number: string;
  delivery_note_number: string;
  suffix_delivery_note_number: string;
  next_delivery_note_number: string;

  prefix_quotation_number: string;
  quotation_number: string;
  suffix_quotation_number: string;
  next_quotation_number: string;

  pro_forma_invoices_enabled?: boolean;
  prefix_pro_forma_invoice_number: string;
  pro_forma_invoice_number: string;
  suffix_pro_forma_invoice_number: string;
  next_pro_forma_invoice_number: string;

  prefix_invoice_number: string;
  invoice_number: string;
  suffix_invoice_number: string;
  next_invoice_number: string;

  billable_rounding: BillableRounding;
  default_identifier_type: RemittanceInformationType;
  yearly_revenue_goal?: string;
  invoice_reminders_enabled: boolean;
  invoice_reminders_interval: number;
  invoice_reminders_limit: number;
  invoice_paid_confirmation_enabled?: boolean;
  default_menu: NavigationMenuItem[];
  po_number_enabled?: boolean;
  default_rate_type?: RateType;
  translations: MobilityTranslations<SettingTranslateableFields>;
  auto_email_bcc_enabled?: boolean;
  bcc_email_addresses?: string[];
  approved_email_addresses?: string[];
  company_brand_color?: string;
}

export type MobilityTranslations<T> = { [Property in Locale]?: T };

export type SettingTranslateableFields = {
  default_pro_forma_invoice_details?: string;
  pro_forma_invoice_email_subject?: string;
  pro_forma_invoice_email_body?: string;
  default_invoice_details?: string;
  invoice_email_subject?: string;
  invoice_email_body?: string;
  quotation_email_subject?: string;
  quotation_email_body?: string;
  default_quotation_details?: string;
  credit_note_email_subject?: string;
  credit_note_email_body?: string;
  default_credit_note_details?: string;
  invoice_reminder_email_subject?: string;
  invoice_reminder_email_body?: string;
  invoice_paid_email_subject?: string;
  invoice_paid_email_body?: string;
  order_form_email_subject?: string;
  order_form_email_body?: string;
  default_order_form_details?: string;
  default_delivery_note_details?: string;
  delivery_note_email_subject?: string;
  delivery_note_email_body?: string;
  contract_email_subject?: string;
  contract_email_body?: string;
  proposal_email_subject?: string;
  proposal_email_body?: string;
};

export enum BillableRounding {
  NONE = "none",
  ROUND_NEAREST_MINUTE = "round_nearest_minute",
  ROUND_NEAREST_QUARTER_HOUR = "round_nearest_quarter_hour",
  ROUND_NEAREST_HALF_HOUR = "round_nearest_half_hour",
  ROUND_NEAREST_HOUR = "round_nearest_hour",
}

export enum SubscriptionStatus {
  TRIALING = "trialing",
  ACTIVE = "active",
  UNPAID = "unpaid",
}
export interface Subscription {
  id: string;
  workspace_id: string;
  plan_id: string;
  status: SubscriptionStatus;
  stripe_id: string;
  trial_end: number;
  plan?: Plan;
}

export interface Card {
  id: string;
  brand: string;
  expires_on: string;
  last_digits: string;
  stripe_id: string;
}

export enum PlanType {
  FREE = "free",
  BUSINESS = "business",
  BUSINESS_PRO = "business_pro",
}

export interface Plan {
  id: string;
  name: string;
  type: PlanType;
  stripe_id: string;
  price: number;
  interval: number;
}

export interface SubscriptionInterval {
  name: string;
  value: number;
}

export interface RequestErrors {
  errors: any;
}

export enum ExpenseType {
  // t('ExpenseType::invoice')
  INVOICE = "invoice",
  // t('ExpenseType::credit_note')
  CREDIT_NOTE = "credit_note",
}

export enum ExpenseStatus {
  // t('ExpenseStatus::overdue')
  OVERDUE = "overdue",
  // t('ExpenseStatus::outstanding')
  OUTSTANDING = "outstanding",
  // t('ExpenseStatus::paid')
  PAID = "paid",
}

export interface Expense {
  id?: string;
  color?: string;
  name?: string;
  type?: ExpenseType;
  notes?: string;
  invoiced_on?: string | any;
  due_on?: string | any;
  paid_on?: string | any;
  net_total?: string | number;
  amount?: string | number;
  billable?: boolean;
  markup?: string | number;
  billable_amount?: string | number;
  billed?: boolean;
  attachment_file_name?: string;
  attachment_content_type?: string;
  attachment_file_size?: number;
  attachment_url?: string | null;
  attachment_download_url?: string | null;
  attachment?: any;
  supplier_id?: string;
  supplier?: Contact;
  category_id?: string;
  category?: ExpenseCategory;
  currency?: string;
  booked_on?: string | Date | null | any;
  contact_id?: string;
  contact?: Contact;
  project_id?: string;
  project?: Project;
  ledger_item?: LedgerItem;
  ledger_item_id?: string;
  custom_fields?: ModelCustomFieldsType;
  recurring_schedule?: RecurringSchedule;
  payment_id?: string;
  payment?: Payment;
  remittance_information?: string;
  remittance_information_type?: RemittanceInformationType;
  peppol_id?: string;
  created_at?: string;

  // OCR related fields
  extraction_data?: ExpenseExtractionData;
}

export interface ExpenseMetadata {
  previous_supplier_expense?: Expense;
}

export enum RemittanceInformationType {
  FREE = "free",
  UNSTRUCTURED = "unstructured",
  STRUCTURED = "structured",
}

export enum LedgerItemStatus {
  // t('LedgerItemStatus::draft')
  DRAFT = "draft",
  // t('LedgerItemStatus::overdue')
  OVERDUE = "overdue",
  // t('LedgerItemStatus::approved')
  APPROVED = "approved",
  // t('LedgerItemStatus::rejected')
  REJECTED = "rejected",
  // t('LedgerItemStatus::invoiced')
  INVOICED = "invoiced",
  // t('LedgerItemStatus::outstanding')
  OUTSTANDING = "outstanding",
  // t('LedgerItemStatus::paid')
  PAID = "paid",
  // t('LedgerItemStatus::delivered')
  DELIVERED = "delivered",
  // t('LedgerItemStatus::cancelled')
  CANCELLED = "cancelled",
  // t('LedgerItemStatus::expired')
  EXPIRED = "expired",
  // t('LedgerItemStatus::credited')
  CREDITED = "credited",
  // t('LedgerItemStatus::scheduled')
  SCHEDULED = "scheduled",
}

export interface LedgerItem {
  id?: string;
  contact_id?: string;
  contact?: Contact;
  project?: Project;
  project_id?: string | null;
  email_contact_id?: string;
  email_contact?: Contact;
  created_at?: string;
  currency?: string;
  details?: string;
  vat_rules?: string;
  due_on?: string | Date | any;
  approved_on?: string | Date | any;
  rejected_on?: string | Date | any;
  estimated_net_on?: string | number;
  identifier?: string;
  identifier_type?: RemittanceInformationType;
  issued_on?: string | Date | any;
  ledger_condition?: LedgerCondition;
  ledger_condition_id?: string;
  line_items?: LineItem[];
  notes?: string;
  number?: string;
  paid_on?: string | Date | any;
  payment_gateway?: string | null;
  payment_id?: string | null;
  sent_at?: string | Date | null;
  subtotal?: string | number;
  discount?: string | number;
  discount_total?: string | number;
  net_total?: string | number;
  tax_total?: string | number;
  amount?: string;
  type?: LedgerItemType;
  workspace_id?: string;
  viewed_at?: string | Date | any;
  updated_at?: string | Date | any;
  booked_on?: string | Date | null | any;
  // Used to indicate when the quotation was invoiced
  invoiced_on?: string | Date | null | any;
  // Used to link a pro forma invoice to an invoice
  pro_forma_invoice_id?: string | null;
  // Use to link a creditnote to an invoice
  invoice_id?: string | null;
  // Used to indicate when the invoice was credited
  credited_on?: string | Date | any;
  // Use to link a invoice to an quote
  quotation_id?: string | null;
  // Used to indicate when the order form has an attached delivery note
  delivered_on?: string | Date | null | any;
  // Used to indicate what delivery note is attached to an order form
  order_form_id?: string | null;
  // Used to indicate what invoice is attached to a delivery note
  delivery_note_id?: string | null;
  // Used to add a purchase order number
  po_number?: string | null;
  cancelled_on?: string | Date | null | any;
  reminders_enabled?: boolean;
  reminders_count?: number;
  last_reminder_sent_at?: string | Date | null;
  recurring_schedule?: RecurringSchedule;
  custom_fields?: ModelCustomFieldsType;
  claim_id?: string;
  deal_id?: string;
  data?: LedgerItemData;
  peppol_id?: string;
  peppol_errors?: { field: string; value: string }[];
  pdf_attachment_file_name?: string;
  pdf_attachment_content_type?: string;
  pdf_attachment_file_size?: number;
  pdf_attachment_url?: string | null;
  pdf_attachment_download_url?: string | null;
}

export interface LedgerItemData {
  payment_confirmation_message?: string;
  payment_confirmation_attachments?: ActiveStorage.Blob[];
}

export enum RecurrenceUnit {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  BIMONTHLY = "bimonthly",
  QUARTERLY = "quarterly",
  SEMESTERLY = "semesterly",
  YEARLY = "yearly",
}
export enum DayOfWeek {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export enum MonthOfYear {
  JANUARY = 1,
  FEBRUARY = 2,
  MARCH = 3,
  APRIL = 4,
  MAY = 5,
  JUNE = 6,
  JULY = 7,
  AUGUST = 8,
  SEPTEMBER = 9,
  OCTOBER = 10,
  NOVEMBER = 11,
  DECEMBER = 12,
}

export interface RecurringSchedule {
  start_date?: string | any;
  end_date?: string | any;
  recurrence_unit?: RecurrenceUnit | null;
  recurrence_interval?: number | null;
  repeat_on_day_of_week?: DayOfWeek[];
  repeat_on_day_of_month?: number | null;
  repeat_on_month_of_year?: MonthOfYear | null;
  timezone?: string;
  repeat_at_end_of_month?: boolean;
  schedulable_id?: string;
  schedulable_type?: string;
  next_occurrence_on?: string | Date | null | any;
}

export interface LedgerCondition {
  id?: string;
  name?: string;
  workspace_id?: string;
  translations: MobilityTranslations<LedgerConditionTranslateableFields>;
  created_at?: string;
  updated_at?: string;
}

export interface LedgerConditionTranslateableFields {
  content?: string;
}

export enum TimeEntryExportType {
  ALL = "all",
  BILLED = "billed",
  UNBILLED = "unbilled",
  BILLABLE = "billable",
  UNBILLABLE = "unbillable",
}

export interface TimeEntry {
  id?: string;
  description?: string;
  started_at?: string | Date;
  ended_at?: string | Date;
  duration?: number;
  base_rate?: number;
  cost_rate?: number;
  multiplier?: number;
  rate?: number;
  hours?: string | number;
  amount?: string | number;
  cost_total?: number;
  billable?: boolean;
  billed?: boolean;
  fixed_rate?: boolean;
  travel_distance?: number;
  active?: boolean;
  workspace_id?: string;
  user_id?: string;
  work_type_id?: string;
  contact_id?: string;
  project_id?: string;
  line_item_id?: string;
  task_id?: string;
  po_number?: string;
  work_type?: WorkType;
  deal_id?: string;
  contact?: Contact;
  project?: Project;
  custom_fields?: ModelCustomFieldsType;
}

export enum VATLiability {
  // t('VATLiability::unknown')
  UNKNOWN_VAT_LIABILITY = "unknown",
  // t('VATLiability::vat_liable')
  VAT_LIABLE_VAT_LIABILITY = "vat_liable",
  // t('VATLiability::intracommunity')
  INTRACOMMUNITY_VAT_LIABILITY = "intracommunity",
  // t('VATLiability::outside_eu')
  OUTSIDE_EU_VAT_LIABILITY = "outside_eu",
  // t('VATLiability::private_person')
  PRIVATE_PERSON_VAT_LIABILITY = "private_person",
  // t('VATLiability::exempted')
  EXEMPTED_VAT_LIABILITY = "exempted",
  // t('VATLiability::co_contractor')
  CO_CONTRACTOR_VAT_LIABILITY = "co_contractor",
}

export const enum ContactType {
  // t('ContactType::person')
  PERSON = "person",
  // t('ContactType::company')
  COMPANY = "company",
}
export interface Person extends ContactBase {
  type?: ContactType.PERSON;
  first_name?: string;
  last_name?: string;
  job_title?: string;
  birthdate?: string | Date;
}
export interface Company extends ContactBase {
  type?: ContactType.COMPANY;
  contacts_count?: number;
}

export type Contact = Person | Company;

export interface ExportContactParams {
  export_type: SpreadsheetExportType;
}

export enum ContactEmailType {
  // t('ContactEmailType::work')
  WORK = "work",
  // t('ContactEmailType::personal')
  PERSONAL = "personal",
  // t('ContactEmailType::other')
  OTHER = "other",
}

export interface ContactEmail {
  id?: string;
  type?: ContactEmailType;
  value?: string;
  position?: number;
  _destroy?: boolean;
}

export enum ContactTelephoneNumberType {
  // t('ContactTelephoneNumberType::work')
  WORK = "work",
  // t('ContactTelephoneNumberType::mobile')
  MOBILE = "mobile",
  // t('ContactTelephoneNumberType::home')
  HOME = "home",
  // t('ContactTelephoneNumberType::fax')
  FAX = "fax",
  // t('ContactTelephoneNumberType::other')
  OTHER = "other",
}

export interface ContactTelephoneNumber {
  id?: string;
  type?: ContactTelephoneNumberType;
  value?: string;
  position?: number;
  _destroy?: boolean;
}

export interface ContactAccountNumber {
  id?: string;
  value?: string;
  position?: number;
  _destroy?: boolean;
}

export enum ContactAddressType {
  // t('ContactAddressType::billing')
  BILLING = "billing",
  // t('ContactAddressType::delivery')
  DELIVERY = "delivery",
}

export interface ContactAddress {
  id?: string;
  type?: ContactAddressType;
  street?: string;
  street_number?: string;
  city?: string;
  zip?: string;
  country?: string;
  state?: string;
  position?: number;
  _destroy?: boolean;
}

export interface ContactWebsite {
  id?: string;
  url?: string;
  position?: number;
  _destroy?: boolean;
}

export interface ContactBase {
  id?: string;
  name?: string;
  background_info?: string;
  alias?: string;
  color?: string;
  locale?: string;
  currency?: string;
  vat_number?: string;
  notes?: string;
  workspace_id?: string;
  referral_source_id?: string;
  referral_source?: ReferralSource;
  hourly_rate?: number;
  day_rate?: number;
  archived?: boolean;
  vat_liability?: VATLiability;
  rate_type?: RateType;
  peppol_id?: string;
  custom_fields?: ModelCustomFieldsType;
  emails?: ContactEmail[];
  telephone_numbers?: ContactTelephoneNumber[];
  account_numbers?: ContactAccountNumber[];
  billing_address?: ContactAddress;
  delivery_address?: ContactAddress;
  websites?: ContactWebsite[];
  contacts?: Contact[];
  groups?: ContactGroup[];
  group_ids?: string[];
  contact?: Contact;
  contact_id?: string;
  starred?: boolean;
  delivery_same_as_invoice_address?: boolean;
  marketing_consent?: boolean;
}

export interface ContactGroup {
  id?: string;
  name?: string;
  contact_count?: number;
  workspace_id?: string;
}

export enum ProjectStatus {
  ACTIVE = "active",
  PROPOSAL = "proposal",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}

export enum ProjectBillableType {
  // t('ProjectBillableType::time_and_materials')
  TIME_AND_MATERIALS = "time_and_materials",
  // t('ProjectBillableType::fixed_budget')
  FIXED_BUDGET = "fixed_budget",
  // t('ProjectBillableType::non_billable')
  NON_BILLABLE = "non_billable",
}

export enum ProjectBudgetType {
  // t('ProjectBudgetType::none')
  NONE = "none",
  // t('ProjectBudgetType::total_costs')
  TOTAL_COSTS = "total_costs",
  // t('ProjectBudgetType::total_hours')
  TOTAL_HOURS = "total_hours",
}

interface BudgetDataCosts {
  type: ProjectBudgetType.TOTAL_COSTS;
  budget: number;
  budget_alert_treshold: number;
  remaining_budget: number;
  labour: number;
  expenses: number;
}

interface BudgetDataHours {
  type: ProjectBudgetType.TOTAL_HOURS;
  budget: number;
  budget_alert_treshold: number;
  remaining_budget: number;
  hours: number;
}

export type BudgetData = BudgetDataCosts | BudgetDataHours;

export interface Project {
  id?: string;
  name?: string;
  color?: string;
  notes?: string;
  billable_type?: ProjectBillableType;
  labour_costs?: number;
  budget_labour_costs?: number;
  expense_costs?: number;
  budget_expense_costs?: number;
  total_costs?: number;
  budget_total_costs?: number;
  billable_hours?: number;
  budget_billable_hours?: number;
  non_billable_hours?: number;
  budget_non_billable_hours?: number;
  total_hours?: number;
  budget_total_hours?: number;
  budget_type?: ProjectBudgetType;
  budget?: number;
  budget_hours?: number;
  budget_alert_treshold?: number;
  budget_spent?: number;
  budget_remaining?: number;
  budget_alert_send_on?: string | Date;
  budget_resets_monthly?: boolean;
  hourly_rate?: number;
  day_rate?: number;
  estimated_start_date?: string;
  estimated_end_date?: string;
  start_date?: string;
  end_date?: string;
  cancel_date?: string;
  status?: ProjectStatus;
  contact_id?: string;
  workspace_id?: string;
  contact?: Contact;
  archived?: boolean;
  po_number?: string;
  rate_type?: RateType;
  custom_fields?: ModelCustomFieldsType;
}

export interface ProjectSummary {
  hours: {
    total: number;
    billable: number;
    non_billable: number;
  };
  budget?: {
    type: ProjectBudgetType;
    total: number;
    used: number;
    remaining: number;
  };
  total_costs: {
    total: number;
    labour: number;
    expenses: number;
  };
  uninvoiced_amount: {
    total: number;
    project: number;
    labour_and_expenses: number;
  };
}

export enum ContactableType {
  CLIENT = "Contact",
  SUPPLIER = "Supplier",
  USER_WORKSPACE_SETTING = "UserWorkspaceSetting",
}

export enum IntegrationType {
  MOLLIE = "mollie",
  STRIPE = "stripe",
  YUKI = "yuki",
  CLEARFACTS = "clearfacts",
  PONTO = "ponto",
  PQR = "pqr",
  MAILCHIMP = "mailchimp",
  CONVERTKIT = "convertkit",
  MAILSYNC = "mailsync",
  UNPAID = "unpaid",
  OUTLOOK = "outlook",
  GMAIL = "gmail",
  OCTOPUS = "octopus",
}

export interface MarketplaceIntegration {
  id?: string;
  active?: boolean;
  new?: boolean;
  image?: string;
  icon?: string;
  title?: string;
  description?: string;
  connected?: boolean;
  theme?: {
    cover?: string;
    text?: string;
    button_text?: string;
    button_background?: string;
  };
}

export interface Integration {
  id?: string;
  type?: IntegrationType;
  access_token?: string;
  refresh_token?: string;
  expires_at?: string;
  config?: IntegrationConfig;
}

export interface IntegrationConfig {}

export interface MollieIntegrationConfig extends IntegrationConfig {
  profile_id?: string;
}

export interface PontoIntegrationConfig extends IntegrationConfig {
  account_id?: string;
}

export interface YukiIntegrationConfig extends IntegrationConfig {
  access_token?: string;
  administration_id?: string;
}

export interface MailchimpIntegrationConfig extends IntegrationConfig {
  audience_id?: string;
  dc?: string;
}

export interface ClearfactsIntegrationConfig extends IntegrationConfig {
  company_number?: string;
}

export interface MailsyncIntegrationConfig extends IntegrationConfig {
  invoice_mailbox_address?: string;
  invoice_mailbox_subject?: string;
  expense_mailbox_address?: string;
  expense_mailbox_subject?: string;
  document_mailbox_address?: string;
  document_mailbox_subject?: string;
}

export interface UnpaidIntegrationConfig extends IntegrationConfig {
  company_name?: string;
  address?: string;
  zip_code?: string;
  location?: string;
  country_code?: string;
  vat_number?: string;
  iban?: string;
  bic?: string;
  contact_name?: string;
  email?: string;
  phone_number?: string;
}

export interface GmailIntegrationConfig extends IntegrationConfig {}
export interface GmailFormData {
  client_id: string;
  scopes: string[];
}

export interface OctopusIntegrationConfig extends IntegrationConfig {
  username?: string;
  password?: string;
  dossier_id?: string;
  invoice_mailbox_address?: string;
  expense_mailbox_address?: string;
  document_mailbox_address?: string;
}

export interface ConvertkitIntegrationConfig extends IntegrationConfig {
  api_key?: string;
  api_secret?: string;
  form_id?: string;
}

export interface ConvertkitFormData {
  forms: { id: string; name: string }[];
}

export interface IntegrationForm {
  type: IntegrationType;
  marketplace_integration: MarketplaceIntegration;
  integration: Integration;
  form_data: any;
}

export interface MollieFormData {
  profiles?: { label: string; value: string }[];
}

export interface PontoFormData {
  accounts?: { label: string; value: string }[];
}

export interface MailchimpFormData {
  audiences: { label: string; value: string }[];
}

export interface ClearfactsFormData {
  administrations: {
    name: string;
    company_number: string;
    emails: { type: string; email_address: string }[];
    company_type: string;
    address: {
      street_address: string;
    };
  }[];
}

export interface UnpaidFormData {
  countries?: { label: string; value: string }[];
}

export interface MailsyncFormData {
  smtp_configured: boolean;
}

export interface OutlookFormData {
  admin_consent_url?: string;
}

export interface OctopusFormData {
  dossiers?: { label: string; value: string }[];
}

export interface LineItem {
  id?: string;
  title?: string;
  description?: string;
  quantity?: number | string;
  price?: number | string;
  discount?: number | string;
  discount_total?: number | string;
  second_tax?: number | string;
  ledger_item_id?: string;
  tax_rate?: number | string;
  tax_code?: string;
  subtotal?: number | string;
  net_total?: number | string;
  tax_total?: number | string;
  total?: number | string;
  entry_ids?: string[];
  expense_ids?: string[];
  product_ids?: string[];
}

export type WorkspaceTaxTranslateableFields = {
  invoice_rule?: string;
  credit_note_rule?: string;
};

export interface WorkspaceTax {
  id?: string;
  code?: string;
  name?: string;
  rate?: number | string;
  translations?: MobilityTranslations<WorkspaceTaxTranslateableFields>;
  vat_liabilities?: VATLiability[];
}

export enum LedgerItemType {
  // t('LedgerItemType::order_form')
  ORDER_FORM = "order_form",
  // t('LedgerItemType::delivery_note')
  DELIVERY_NOTE = "delivery_note",
  // t('LedgerItemType::quotation')
  QUOTATION = "quotation",
  // t('LedgerItemType::pro_forma_invoice')
  PRO_FORMA_INVOICE = "pro_forma_invoice",
  // t('LedgerItemType::invoice')
  INVOICE = "invoice",
  // t('LedgerItemType::credit_note')
  CREDIT_NOTE = "credit_note",
  // t('LedgerItemType::recurring_invoice')
  RECURRING_INVOICE = "recurring_invoice",
}

export interface ImportedProduct {
  productId: string;
  quantity: number;
  product: Product;
}

export interface Product {
  id?: string;
  name?: string;
  notes?: string;
  description?: string;
  purchase_price?: number | string;
  price?: number | string;
  gross_price?: number | string;
  tax_rate?: number | string;
  tax_code?: string;
  stock?: number | string;
  minimum_stock?: number | string;
  sku?: string;
  barcode?: string;
  workspace_id?: string;
  supplier_id?: string;
  supplier?: Contact;
  physical?: boolean;
  custom_fields?: ModelCustomFieldsType;
}

export interface Board {
  id?: string;
  name?: string;
  color?: string;
  archived?: boolean;
  contact_id?: string;
  contact?: Contact;
  project_id?: string;
  project?: Project;
  workspace_id?: string;
  lists?: BoardList[];
}

export interface BoardTemplate {
  id?: string;
  name?: string;
}

export interface BoardList {
  id?: string;
  name?: string;
  position?: number;
  board_id?: string;
}

export interface BoardListMetadata {
  positions: number[];
}

export interface BoardLabel {
  id?: string;
  name?: string;
  color?: string;
  board_id?: string;
}

export interface Task {
  id?: string;
  name?: string;
  description?: string;
  start_on?: string | null;
  due_on?: string | null;
  completed?: boolean;
  position?: number;
  telephone_number?: string;
  location?: string;
  workspace_id?: string;
  board_id?: string;
  board?: Board;
  list_id?: string;
  list?: BoardList;
  cover_id?: string;
  deal_id?: string;
  cover?: BizzeyFile;
  labels?: BoardLabel[];
  label_ids?: string[];
  checklists?: TaskChecklist[];
  attachments?: BizzeyFile[];
  custom_fields?: ModelCustomFieldsType;
  assignee_ids?: string[];
  assignees?: User[];
  contact_id?: string;
  contact?: Contact;
  project_id?: string;
  project?: Project;
  playbook_id?: string;
  time_estimate?: number;
  time_planned?: number;
  time_remaining?: number;
  time_spent?: number;
  created_at?: string;
}

export interface TaskChecklist {
  name?: string;
  position?: number;
  items?: TaskChecklistItem[];
}

export interface TaskChecklistItem {
  name?: string;
  completed?: boolean;
  position?: number;
}

export type NotificationType =
  | "ledger_item_opened"
  | "invoice_paid"
  | "subscription_payment_failed";

export enum WorkspaceCableEventType {
  START_TIMER = "START_TIMER",
  UPDATE_TIMER = "UPDATE_TIMER",
  PAUSE_TIMER = "PAUSE_TIMER",
  STOP_TIMER = "STOP_TIMER",
  RESET_TIMER = "RESET_TIMER",
  CREATE_TIME_ENTRY = "CREATE_TIME_ENTRY",
  UPDATE_TIME_ENTRY = "UPDATE_TIME_ENTRY",
  DELETE_TIME_ENTRY = "DELETE_TIME_ENTRY",
}

export enum WorkspaceEvents {
  UPDATE_TIMER = "UPDATE_TIMER",
}

// DEPRECATING THIS
export type WorkspaceCableEvent =
  | StartTimerEvent
  | PauseTimerEvent
  | StopTimerEvent
  | ResetTimerEvent
  | CreateTimeEntryEvent
  | UpdateTimeEntryEvent
  | DeleteTimeEntryEvent;

export interface WorkspaceCableEventBase {
  type: WorkspaceCableEventType;
  data: object;
}

export interface StartTimerEvent extends WorkspaceCableEventBase {
  type: WorkspaceCableEventType.START_TIMER;
  data: {
    entry: TimeEntry;
  };
}

export interface PauseTimerEvent extends WorkspaceCableEventBase {
  type: WorkspaceCableEventType.PAUSE_TIMER;
  data: {
    entry: TimeEntry;
  };
}

export interface StopTimerEvent extends WorkspaceCableEventBase {
  type: WorkspaceCableEventType.STOP_TIMER;
  data: {
    entry: TimeEntry;
  };
}

export interface ResetTimerEvent extends WorkspaceCableEventBase {
  type: WorkspaceCableEventType.RESET_TIMER;
  data: {
    entry: TimeEntry;
  };
}

export interface CreateTimeEntryEvent extends WorkspaceCableEventBase {
  type: WorkspaceCableEventType.CREATE_TIME_ENTRY;
  data: {
    entry: TimeEntry;
  };
}

export interface UpdateTimeEntryEvent extends WorkspaceCableEventBase {
  type: WorkspaceCableEventType.UPDATE_TIME_ENTRY;
  data: {
    entry: {
      entry: TimeEntry;
    };
  };
}

export interface DeleteTimeEntryEvent extends WorkspaceCableEventBase {
  type: WorkspaceCableEventType.DELETE_TIME_ENTRY;
  data: {
    entry: TimeEntry;
  };
}

// END DEPRECATION

export enum WorkspaceChannelEventType {
  WORKSPACE_CREATE = "WORKSPACE_CREATE",
  WORKSPACE_UPDATE = "WORKSPACE_UPDATE",
  WORKSPACE_DELETE = "WORKSPACE_DELETE",

  SUBSCRIPTION_CREATE = "SUBSCRIPTION_CREATE",
  SUBSCRIPTION_UPDATE = "SUBSCRIPTION_UPDATE",
  SUBSCRIPTION_DELETE = "SUBSCRIPTION_DELETE",

  SETTING_CREATE = "SETTING_CREATE",
  SETTING_UPDATE = "SETTING_UPDATE",
  SETTING_DELETE = "SETTING_DELETE",

  DEAL_STAGE_CREATE = "DEAL_STAGE_CREATE",
  DEAL_STAGE_UPDATE = "DEAL_STAGE_UPDATE",
  DEAL_STAGE_DELETE = "DEAL_STAGE_DELETE",

  DEAL_CREATE = "DEAL_CREATE",
  DEAL_UPDATE = "DEAL_UPDATE",
  DEAL_DELETE = "DEAL_DELETE",

  BOARD_CREATE = "BOARD_CREATE",
  BOARD_UPDATE = "BOARD_UPDATE",
  BOARD_DELETE = "BOARD_DELETE",

  BOARD_LABEL_CREATE = "BOARD_LABEL_CREATE",
  BOARD_LABEL_UPDATE = "BOARD_LABEL_UPDATE",
  BOARD_LABEL_DELETE = "BOARD_LABEL_DELETE",

  BOARD_LIST_CREATE = "BOARD_LIST_CREATE",
  BOARD_LIST_UPDATE = "BOARD_LIST_UPDATE",
  BOARD_LIST_DELETE = "BOARD_LIST_DELETE",

  TASK_CREATE = "TASK_CREATE",
  TASK_UPDATE = "TASK_UPDATE",
  TASK_DELETE = "TASK_DELETE",

  LEDGER_ITEM_ATTACHMENTS_READY = "LEDGER_ITEM_ATTACHMENTS_READY",

  ACCOUNT_CREATE = "ACCOUNT_CREATE",
  ACCOUNT_UPDATE = "ACCOUNT_UPDATE",
  ACCOUNT_DELETE = "ACCOUNT_DELETE",

  TRANSACTION_CREATE = "TRANSACTION_CREATE",
  TRANSACTION_UPDATE = "TRANSACTION_UPDATE",
  TRANSACTION_DELETE = "TRANSACTION_DELETE",
}

export interface WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType;
  data: object;
}

export interface WorkspaceChannelCreateWorkspaceEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.WORKSPACE_CREATE;
  data: { workspace: Workspace };
}

export interface WorkspaceChannelUpdateWorkspaceEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.WORKSPACE_UPDATE;
  data: { workspace: Workspace };
}

export interface WorkspaceChannelDeleteWorkspaceEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.WORKSPACE_DELETE;
  data: { workspace_id: string };
}

export interface WorkspaceChannelCreateSubscriptionEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.SUBSCRIPTION_CREATE;
  data: { subscription: Subscription };
}

export interface WorkspaceChannelUpdateSubscriptionEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.SUBSCRIPTION_UPDATE;
  data: { subscription: Subscription };
}

export interface WorkspaceChannelDeleteSubscriptionEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.SUBSCRIPTION_DELETE;
  data: { workspace_id: string; subscription_id: string };
}

export interface WorkspaceChannelCreateSettingEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.SETTING_CREATE;
  data: { setting: Settings };
}

export interface WorkspaceChannelUpdateSettingEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.SETTING_UPDATE;
  data: { setting: Settings };
}

export interface WorkspaceChannelDeleteSettingEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.SETTING_DELETE;
  data: { setting_id: string };
}

export interface WorkspaceChannelCreateDealStageEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.DEAL_STAGE_CREATE;
  data: { deal_stage: DealStage };
}

export interface WorkspaceChannelUpdateDealStageEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.DEAL_STAGE_UPDATE;
  data: { deal_stage: DealStage };
}

export interface WorkspaceChannelDeleteDealStageEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.DEAL_STAGE_DELETE;
  data: { deal_stage_id: string };
}

export interface WorkspaceChannelCreateDealEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.DEAL_CREATE;
  data: { deal: Deal };
}

export interface WorkspaceChannelUpdateDealEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.DEAL_UPDATE;
  data: { deal: Deal };
}

export interface WorkspaceChannelDeleteDealEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.DEAL_DELETE;
  data: { deal_id: string };
}

export interface WorkspaceChannelCreateBoardEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.BOARD_CREATE;
  data: { board: Board };
}

export interface WorkspaceChannelUpdateBoardEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.BOARD_UPDATE;
  data: { board: Board };
}

export interface WorkspaceChannelDeleteBoardEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.BOARD_DELETE;
  data: { board_id: string };
}

export interface WorkspaceChannelCreateBoardLabelEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.BOARD_LABEL_CREATE;
  data: { board_label: BoardLabel };
}

export interface WorkspaceChannelUpdateBoardLabelEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.BOARD_LABEL_UPDATE;
  data: { board_label: BoardLabel };
}

export interface WorkspaceChannelDeleteBoardLabelEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.BOARD_LABEL_DELETE;
  data: { board_id: string; board_label_id: string };
}

export interface WorkspaceChannelCreateBoardListEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.BOARD_LIST_CREATE;
  data: { board_list: BoardList };
}

export interface WorkspaceChannelUpdateBoardListEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.BOARD_LIST_UPDATE;
  data: { board_list: BoardList };
}

export interface WorkspaceChannelDeleteBoardListEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.BOARD_LIST_DELETE;
  data: { board_id: string; board_list_id: string };
}

export interface WorkspaceChannelCreateTaskEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.TASK_CREATE;
  data: { task: Task };
}

export interface WorkspaceChannelUpdateTaskEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.TASK_UPDATE;
  data: { task: Task };
}

export interface WorkspaceChannelDeleteTaskEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.TASK_DELETE;
  data: { board_id?: string; task_id: string };
}

export interface WorkspaceChannelLedgerItemAttachmentsReadyEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.LEDGER_ITEM_ATTACHMENTS_READY;
  data: { ledger_item: LedgerItem };
}

export interface WorkspaceChannelCreateAccountEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.ACCOUNT_CREATE;
  data: { account: Account };
}

export interface WorkspaceChannelUpdateAccountEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.ACCOUNT_UPDATE;
  data: { account: Account };
}

export interface WorkspaceChannelDeleteAccountEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.ACCOUNT_DELETE;
  data: { account_id: string };
}

export interface WorkspaceChannelCreateTransactionEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.TRANSACTION_CREATE;
  data: { transaction: Transaction };
}

export interface WorkspaceChannelUpdateTransactionEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.TRANSACTION_UPDATE;
  data: { transaction: Transaction };
}

export interface WorkspaceChannelDeleteTransactionEvent
  extends WorkspaceChannelEventBase {
  type: WorkspaceChannelEventType.TRANSACTION_DELETE;
  data: { transaction_id: string };
}

export type WorkspaceChannelEvent =
  | WorkspaceChannelCreateWorkspaceEvent
  | WorkspaceChannelUpdateWorkspaceEvent
  | WorkspaceChannelDeleteWorkspaceEvent
  | WorkspaceChannelCreateSubscriptionEvent
  | WorkspaceChannelUpdateSubscriptionEvent
  | WorkspaceChannelDeleteSubscriptionEvent
  | WorkspaceChannelCreateSettingEvent
  | WorkspaceChannelUpdateSettingEvent
  | WorkspaceChannelDeleteSettingEvent
  | WorkspaceChannelCreateDealStageEvent
  | WorkspaceChannelUpdateDealStageEvent
  | WorkspaceChannelDeleteDealStageEvent
  | WorkspaceChannelCreateDealEvent
  | WorkspaceChannelUpdateDealEvent
  | WorkspaceChannelDeleteDealEvent
  | WorkspaceChannelCreateBoardEvent
  | WorkspaceChannelUpdateBoardEvent
  | WorkspaceChannelDeleteBoardEvent
  | WorkspaceChannelCreateBoardLabelEvent
  | WorkspaceChannelUpdateBoardLabelEvent
  | WorkspaceChannelDeleteBoardLabelEvent
  | WorkspaceChannelCreateBoardListEvent
  | WorkspaceChannelUpdateBoardListEvent
  | WorkspaceChannelDeleteBoardListEvent
  | WorkspaceChannelCreateTaskEvent
  | WorkspaceChannelUpdateTaskEvent
  | WorkspaceChannelDeleteTaskEvent
  | WorkspaceChannelLedgerItemAttachmentsReadyEvent
  | WorkspaceChannelCreateAccountEvent
  | WorkspaceChannelUpdateAccountEvent
  | WorkspaceChannelDeleteAccountEvent
  | WorkspaceChannelCreateTransactionEvent
  | WorkspaceChannelUpdateTransactionEvent
  | WorkspaceChannelDeleteTransactionEvent;

export interface ExpenseCategory {
  id?: string;
  name?: string;
  color?: string;
  editable?: boolean;
  parent_id?: string;
  workspace_id?: string;
  sub_categories?: ExpenseCategory[];
  parent_category?: ExpenseCategory | null;
}

export interface LedgerItemTemplate {
  id?: string;
  name?: string;
  premium?: boolean;
  enabled?: boolean;
  cover_url?: string;
}

export interface ReferralSource {
  id?: string;
  name?: string;
}

export enum ImportTimeEntryGroupByOption {
  CUSTOM_DESCRIPTION = "custom_description",
  DESCRIPTION = "description",
  WORK_TYPE = "work_type",
  DATE = "date",
  DATE_HOUR_DESCRIPTION = "date_hour_description",
}

export interface GroupedTimeEntries {
  description: string;
  rate: number;
  entries: TimeEntry[];
  fixed_rate: boolean;
}

export type PermissionRole = "owner" | "accountant";

export interface Accountant {
  id?: string;
  email?: string;
  workspace?: Workspace;
}

export interface AccountantCurrentUser extends Accountant {}

export interface Device {
  id?: string;
  token?: string | null | undefined;
  user_id?: string;
}

export enum MimeTypes {
  ALL = "*",
  PDF = "application/pdf",
  JPEG = "image/jpeg",
  JPG = "image/jpg",
  PNG = "image/png",
  GIF = "image/gif",
  SVG = "image/svg+xml",
  CSV = "text/csv",
  EXCEL = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  TEXT_XML = "text/xml",
  APPLICATION_XML = "application/xml",
}

export type PaymentForm = MolliePaymentForm | StripePaymentForm;

export interface MolliePaymentForm {
  type: IntegrationType.MOLLIE;
  data: {
    id: string;
    _links: {
      checkout: {
        href: string;
      };
    };
  };
}

export interface StripePaymentForm {
  type: IntegrationType.STRIPE;
  data: {
    public_key: string;
    account_id: string;
    session_id: string;
  };
}

export enum BizzeyFileType {
  DIRECTORY = "directory",
  FILE = "file",
}

export enum BizzeyFileResourceType {
  CONTACT = "Contact",
  PROJECT = "Project",
  TASK = "Task",
  WORKSPACE = "Workspace",
  DEAL = "Deal",
}

export interface BizzeyFile {
  id?: string;
  name?: string;
  type?: BizzeyFileType;
  parent_file_id?: string;
  attachment_file_name?: string;
  attachment_content_type?: string;
  attachment_file_size?: number;
  attachment_url?: string | null;
  attachment_download_url?: string | null;
  attachment?: any;
  resource_type?: BizzeyFileResourceType;
  resource_id?: string;
  created_at?: string;
  updated_at?: string;
}

export interface InvoiceMetadata {
  project_budget?: number;
  unbilled_time_entries: {
    count: number;
    amount: number;
  };
  unbilled_expenses: {
    count: number;
    amount: number;
  };
  advancement_invoices: {
    quotation_number?: string;
    count?: number;
    remaining_net_amount?: number;
  };
}

export enum SmtpAuthType {
  LOGIN = "login",
  PLAIN = "plain",
  CRAM_MD5 = "cram-md5",
}

export enum SmtpSSLType {
  SSL = "ssl",
  TLS = "tls",
}
export interface SMTP {
  id?: string;
  helo_domain?: string;
  domain?: string;
  username?: string;
  password?: string;
  auth_type?: SmtpAuthType;
  ssl_type?: SmtpSSLType;
  port?: number;
  from_name?: string;
  from_email?: string;
}

export interface SendEmailParams {
  to?: string[];
  cc?: string[];
  bcc?: string[];
  subject?: string;
  body?: string;
  attachment_ids?: string[];
  test_email?: boolean;
}

export interface SendLedgerItemEmailParams {
  ledger_item_id: string;
  to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  body: string;
  reminder?: boolean;
  attach_ledger_item?: boolean;
  attachment_ids: string[];
  test_email?: boolean;
}

export interface PreviewAttachment {
  id: string;
  name: string;
  url: string;
  file_size: number;
  content_type: string;
  created_at?: string;
}

export enum CustomFieldType {
  // i18n.t('CustomFieldType::string')
  STRING = "string",
  // i18n.t('CustomFieldType::text')
  TEXT = "text",
  // i18n.t('CustomFieldType::single_option')
  SINGLE_OPTION = "single_option",
  // i18n.t('CustomFieldType::multiple_option')
  MULTIPLE_OPTION = "multiple_option",
  // i18n.t('CustomFieldType::date')
  DATE = "date",
  // i18n.t('CustomFieldType::money')
  MONEY = "money",
  // i18n.t('CustomFieldType::number')
  NUMBER = "number",
  // i18n.t('CustomFieldType::boolean')
  BOOLEAN = "boolean",
  // i18n.t('CustomFieldType::email')
  EMAIL = "email",
  // i18n.t('CustomFieldType::phone_number')
  PHONE_NUMBER = "phone_number",
  // i18n.t('CustomFieldType::url')
  URL = "url",
}

export enum CustomFieldModel {
  // i18n.t('CustomFieldModel::contact')
  // i18n.t('CustomFieldModel::contact_plural')
  CLIENT = "contact",
  // i18n.t('CustomFieldModel::supplier')
  // i18n.t('CustomFieldModel::supplier_plural')
  SUPPLIER = "supplier",
  // i18n.t('CustomFieldModel::project')
  // i18n.t('CustomFieldModel::project_plural')
  PROJECT = "project",
  // i18n.t('CustomFieldModel::time_entry')
  // i18n.t('CustomFieldModel::time_entry_plural')
  TIME_ENTRY = "time_entry",
  // i18n.t('CustomFieldModel::invoice')
  // i18n.t('CustomFieldModel::invoice_plural')
  INVOICE = "invoice",
  // i18n.t('CustomFieldModel::expense')
  // i18n.t('CustomFieldModel::expense_plural')
  EXPENSE = "expense",
  // i18n.t('CustomFieldModel::product')
  // i18n.t('CustomFieldModel::product_plural')
  PRODUCT = "product",
  // i18n.t('CustomFieldModel::task')
  // i18n.t('CustomFieldModel::task_plural')
  TASK = "task",
  // i18n.t('CustomFieldModel::calendar_event')
  // i18n.t('CustomFieldModel::calendar_event_plural')
  CALENDAR_EVENT = "calendar_event",
}

export interface SingleOptionCustomFieldConfig {
  options: { label: string; value: string }[];
}
export interface MultipleOptionCustomFieldConfig {
  options: { label: string; value: string }[];
}

export type CustomFieldConfig =
  | object
  | SingleOptionCustomFieldConfig
  | MultipleOptionCustomFieldConfig;

export interface CustomFieldBase {
  id?: string;
  name?: string;
  type?: CustomFieldType | string;
  model?: CustomFieldModel | string;
  required?: boolean;
  position?: number;
  workspace_id?: string;
  config?: CustomFieldConfig;
}

export interface StringCustomField extends CustomFieldBase {
  type: CustomFieldType.STRING;
}

export interface TextCustomField extends CustomFieldBase {
  type: CustomFieldType.TEXT;
}

export interface SingleOptionCustomField extends CustomFieldBase {
  type: CustomFieldType.SINGLE_OPTION;
  config?: {
    options: { label: string; value: string }[];
    allowOptionCreation?: boolean;
  };
}

export interface MultipleOptionCustomField extends CustomFieldBase {
  type: CustomFieldType.MULTIPLE_OPTION;
  config?: {
    options: { label: string; value: string }[];
    allowOptionCreation?: boolean;
  };
}

export interface DateCustomField extends CustomFieldBase {
  type: CustomFieldType.DATE;
}

export interface MoneyCustomField extends CustomFieldBase {
  type: CustomFieldType.MONEY;
}

export interface NumberCustomField extends CustomFieldBase {
  type: CustomFieldType.NUMBER;
}

export interface BooleanCustomField extends CustomFieldBase {
  type: CustomFieldType.BOOLEAN;
}

export interface EmailCustomField extends CustomFieldBase {
  type: CustomFieldType.EMAIL;
}

export interface PhonenumberCustomField extends CustomFieldBase {
  type: CustomFieldType.PHONE_NUMBER;
}

export interface UrlCustomField extends CustomFieldBase {
  type: CustomFieldType.URL;
}

export type CustomField =
  | StringCustomField
  | TextCustomField
  | SingleOptionCustomField
  | MultipleOptionCustomField
  | DateCustomField
  | MoneyCustomField
  | NumberCustomField
  | BooleanCustomField
  | EmailCustomField
  | PhonenumberCustomField
  | UrlCustomField;

export type ModelCustomFieldsType = {
  [key: string]: any;
};

export enum ResourceListFilterType {
  STRING = "text",
  SINGLE_OPTION = "single_option",
  SINGLE_RESOURCE = "single_resource",
  MULTI_RESOURCE = "multi_resource",
  MULTIPLE_OPTION = "multiple_option",
  DATE = "date",
  NUMBER = "number",
  BOOLEAN = "boolean",
}

export enum ResourceListFilterPredicate {
  // i18n.t('ResourceListFilterPredicate::contains')
  CONTAINS = "contains",
  // i18n.t('ResourceListFilterPredicate::equals')
  EQUALS = "equals",
  // i18n.t('ResourceListFilterPredicate::between')
  BETWEEN = "between",
  // i18n.t('ResourceListFilterPredicate::greaterThan')
  GREATER_THAN = "greaterThan",
  // i18n.t('ResourceListFilterPredicate::lessThan')
  LESS_THAN = "lessThan",
  // i18n.t('ResourceListFilterPredicate::isAfter')
  IS_AFTER = "isAfter",
  // i18n.t('ResourceListFilterPredicate::isAfterOrOn')
  IS_AFTER_OR_ON = "isAfterOrOn",
  // i18n.t('ResourceListFilterPredicate::isBefore')
  IS_BEFORE = "isBefore",
  // i18n.t('ResourceListFilterPredicate::isBeforeOrOn')
  IS_BEFORE_OR_ON = "isBeforeOrOn",
  // i18n.t('ResourceListFilterPredicate::present')
  PRESENT = "present",
}

export interface ResourceFilterBase {
  name: string;
  label: string;
  visible?: boolean;
}

export interface StringResourceFilter extends ResourceFilterBase {
  type: ResourceListFilterType.STRING;
}

export interface SingleOptionResourceFilter extends ResourceFilterBase {
  type: ResourceListFilterType.SINGLE_OPTION;
  options: { label: string; value: string }[];
}

export interface SingleResourceFilter extends ResourceFilterBase {
  type: ResourceListFilterType.SINGLE_RESOURCE;
  resourceType: ResourceCreatablePowerSelectType;
  params?: { [key: string]: any };
  createParams?: object;
  isValidNewOption?: (
    inputValue: string,
    value: { label: string; value: string },
    options: any
  ) => boolean;
}

export interface MultiResourceFilter extends ResourceFilterBase {
  type: ResourceListFilterType.MULTI_RESOURCE;
  resourceType: ResourceCreatablePowerSelectType;
  params?: { [key: string]: any };
  createParams?: object;
  isValidNewOption?: (
    inputValue: string,
    value: { label: string; value: string },
    options: any
  ) => boolean;
}

export interface MultipleOptionResourceFilter extends ResourceFilterBase {
  type: ResourceListFilterType.MULTIPLE_OPTION;
  options: { label: string; value: string }[];
}

export interface DateResourceFilter extends ResourceFilterBase {
  type: ResourceListFilterType.DATE;
}

export interface NumberResourceFilter extends ResourceFilterBase {
  type: ResourceListFilterType.NUMBER;
}

export interface BooleanResourceFilter extends ResourceFilterBase {
  type: ResourceListFilterType.BOOLEAN;
}

export type ResourceFilter =
  | StringResourceFilter
  | SingleOptionResourceFilter
  | SingleResourceFilter
  | MultiResourceFilter
  | MultipleOptionResourceFilter
  | DateResourceFilter
  | NumberResourceFilter
  | BooleanResourceFilter;

export interface IPositionable {
  position?: number;
}

/**
 * Used to define events related to a resource
 */
export enum CalendarResourceType {
  PROJECT_EVENT_TYPE = "project",
  QUOTATION_EVENT_TYPE = "quotation",
  INVOICE_EVENT_TYPE = "invoice",
  TASK_EVENT_TYPE = "task",
  CALENDAR_EVENT_TYPE = "calendar_event",
  DEAL_EVENT_TYPE = "deal",
}

export enum CalendarEventReminderType {
  // i18n.t('CalendarEventReminderType::notification')
  NOTIFICATION = "notification",
  // i18n.t('CalendarEventReminderType::email')
  EMAIL = "email",
}

export enum CalendarEventReminderUnit {
  // i18n.t('CalendarEventReminderUnit::minute')
  // i18n.t('CalendarEventReminderUnit::minute_plural')
  MINUTE = "minute",
  // i18n.t('CalendarEventReminderUnit::hour')
  // i18n.t('CalendarEventReminderUnit::hour_plural')
  HOUR = "hour",
  // i18n.t('CalendarEventReminderUnit::day')
  // i18n.t('CalendarEventReminderUnit::day_plural')
  DAY = "day",
  // i18n.t('CalendarEventReminderUnit::week')
  // i18n.t('CalendarEventReminderUnit::week_plural')
  WEEK = "week",
}
export interface CalendarEventReminder {
  type?: CalendarEventReminderType;
  interval?: number;
  interval_unit?: CalendarEventReminderUnit;
}

export enum CalendarEventType {
  // i18n.t('CalendarEventType::event')
  EVENT = "event",
  // i18n.t('CalendarEventType::appointment')
  APPOINTMENT = "appointment",
  // i18n.t('CalendarEventType::call')
  CALL = "call",
  // i18n.t('CalendarEventType::focus_time')
  FOCUS_TIME = "focus_time",
  // i18n.t('CalendarEventType::leave')
  HOLIDAY = "leave",
}

export enum CalendarEventStatus {
  // i18n.t('CalendarEventStatus::confirmed')
  CONFIRMED = "confirmed",
  // i18n.t('CalendarEventStatus::tentative')
  TENTATIVE = "tentative",
  // i18n.t('CalendarEventStatus::cancelled')
  CANCELLED = "cancelled",
}

export enum CalendarEventProvider {
  // i18n.t('CalendarEventProvider::google')
  GOOGLE = "google",
  // i18n.t('CalendarEventProvider::microsoft')
  MICROSOFT = "microsoft",
}

export enum CalendarEventAttendeeStatus {
  ACTION_REQUIRED = "action_required",
  DECLINED = "declined",
  TENTATIVE = "tentative",
  ACCEPTED = "accepted",
}

export interface CalendarEventAttendee {
  id?: string;
  calendar_event_id?: string;
  calendar_event?: CalendarEvent;
  contact_id?: string;
  contact?: Contact;
  status?: CalendarEventAttendeeStatus;
  _destroy?: boolean;
}

export interface CalendarEvent {
  id?: string;
  title?: string;
  type?: CalendarEventType;
  status?: CalendarEventStatus;
  start?: string | Date;
  end?: string | Date;
  timezone?: string;
  all_day?: boolean;
  recurring_schedule?: RecurringSchedule;
  location?: string;
  description?: string;
  attendees?: CalendarEventAttendee[];
  reminders?: CalendarEventReminder[];
  provider?: CalendarEventProvider;
  uid?: string;
  meeting_link?: string;
  custom_fields?: ModelCustomFieldsType;
  contact?: Contact;
  project?: Project;
  contact_id?: string;
  project_id?: string;
  deal_id?: string;
  user?: User;
  user_id?: string;
  workspace_id?: string;
  created_at?: string;
}

export enum CalendarViewEventType {
  // i18n.t('CalendarViewEventType::calendar_event')
  // i18n.t('CalendarViewEventType::calendar_event_plural')
  CALENDAR_EVENT = "calendar_event",
  // i18n.t('CalendarViewEventType::project')
  // i18n.t('CalendarViewEventType::project_plural')
  PROJECT = "project",
  // i18n.t('CalendarViewEventType::quotation')
  // i18n.t('CalendarViewEventType::quotation_plural')
  QUOTATION = "quotation",
  // i18n.t('CalendarViewEventType::invoice')
  // i18n.t('CalendarViewEventType::invoice_plural')
  INVOICE = "invoice",
  // i18n.t('CalendarViewEventType::recurring_invoice')
  // i18n.t('CalendarViewEventType::recurring_invoice_plural')
  RECURRING_INVOICE = "recurring_invoice",
  // i18n.t('CalendarViewEventType::task')
  // i18n.t('CalendarViewEventType::task_plural')
  TASK = "task",
  // i18n.t('CalendarViewEventType::planned_task')
  // i18n.t('CalendarViewEventType::planned_task_plural')
  PLANNED_TASK = "planned_task",
  // i18n.t('CalendarViewEventType::deal')
  // i18n.t('CalendarViewEventType::deal_plural')
  DEAL = "deal",
}

export interface CalendarViewEventBase {
  title?: string;
  start?: Date;
  end?: Date;
  all_day?: boolean;
  type: CalendarViewEventType;
  disabled?: boolean;
}

export interface CalendarViewProjectEvent extends CalendarViewEventBase {
  type: CalendarViewEventType.PROJECT;
  resource: Project;
}

export interface CalendarViewQuotationEvent extends CalendarViewEventBase {
  type: CalendarViewEventType.QUOTATION;
  resource: LedgerItem;
}

export interface CalendarViewInvoiceEvent extends CalendarViewEventBase {
  type: CalendarViewEventType.INVOICE;
  resource: LedgerItem;
}

export interface CalendarViewRecurringInvoiceEvent
  extends CalendarViewEventBase {
  type: CalendarViewEventType.RECURRING_INVOICE;
  resource: LedgerItem;
}

export interface CalendarViewTaskEvent extends CalendarViewEventBase {
  type: CalendarViewEventType.TASK;
  resource: Task;
}

export interface CalendarViewPlannedTaskEvent extends CalendarViewEventBase {
  type: CalendarViewEventType.PLANNED_TASK;
  resource: Task;
}

export interface CalendarViewCalendarEvent extends CalendarViewEventBase {
  type: CalendarViewEventType.CALENDAR_EVENT;
  resource: CalendarEvent;
}

export interface CalendarViewDealEvent extends CalendarViewEventBase {
  type: CalendarViewEventType.DEAL;
  resource: Deal;
}

export type CalendarViewEvent =
  | CalendarViewProjectEvent
  | CalendarViewQuotationEvent
  | CalendarViewInvoiceEvent
  | CalendarViewRecurringInvoiceEvent
  | CalendarViewTaskEvent
  | CalendarViewPlannedTaskEvent
  | CalendarViewCalendarEvent
  | CalendarViewDealEvent;

export type ResourceCreatablePowerSelectType =
  | "company"
  | "person"
  | "contact"
  | "company_with_email"
  | "person_with_email"
  | "contact_with_email"
  | "contact_group"
  | "project"
  | "user"
  | "contact"
  | "deal_stage"
  | "deal"
  | "work_type"
  | "board"
  | "board_list"
  | "document_tag"
  | "playbook"
  | "sequence"
  | "account";

export interface DisplayableError {
  field: string;
  message: string;
}

export interface ExtractionTax {
  code?: string;
  amount?: number;
  rate?: number;
}

export interface ExtractionLineItem {
  description?: string;
  product_code?: string;
  quantity?: number;
  price?: number;
  tax_amount?: number;
  tax_rate?: number;
  total_amount?: number;
}

export interface InvoiceExtractionData {
  type: "invoice";
  currency?: string;
  issue_date?: string;
  due_date?: string;
  locale?: string;
  supplier_account_number?: string;
  supplier_address?: string;
  supplier_iban?: string;
  supplier_name?: string;
  supplier_routing_number?: string;
  supplier_swift?: string;
  supplier_vat?: string;
  taxes?: Array<ExtractionTax>;
  total_exclusive?: number;
  total_inclusive?: number;
  total_tax?: number;
  line_items?: Array<ExtractionLineItem>;
}

export type ReceiptExtractionCategory =
  | "accommodation"
  | "food"
  | "gasoline"
  | "parking"
  | "transport"
  | "toll"
  | "miscellaneous";
export interface ReceiptExtractionData {
  type: "receipt";
  currency?: string;
  country?: string;
  issue_date?: string;
  time?: string;
  locale?: string;
  supplier_name?: string;
  supplier_vat?: string | null;
  taxes?: Array<ExtractionTax>;
  total_exclusive?: number;
  total_inclusive?: number;
  total_tax?: number;
  category: ReceiptExtractionCategory;
}

export interface UBLExtractionData {
  type: "ubl";
  data: UBLData;
}

export type ExpenseExtractionData =
  | InvoiceExtractionData
  | ReceiptExtractionData
  | UBLExtractionData;

export enum ContractStatus {
  // i18n.t('ContractStatus::draft')
  DRAFT = "draft",
  // i18n.t('ContractStatus::pending')
  PENDING = "pending",
  // i18n.t('ContractStatus::signed')
  SIGNED = "signed",
  // i18n.t('ContractStatus::rejected')
  REJECTED = "rejected",
}

export interface Contract {
  id?: string;
  name?: string;
  status?: ContractStatus;
  workspace_id?: string;
  contact_id?: string;
  contact?: Contact;
  project_id?: string;
  project?: Project;
  signees?: Contact[];
  signee_ids?: string[];
  created_at?: string;
  content_blocks?: ContentBlock[];
  signatures?: Signature[];
}

export enum ContentBlockResource {
  CONTRACT = "contract",
  PROPOSAL = "proposal",
  FORM = "form",
  PLAYBOOK = "playbook",
  CONTENT_BLOCK_TEMPLATE = "content_block_template",
}

export enum ContentBlockType {
  // i18n.t('ContentBlockType::text')
  TEXT = "text",
  // i18n.t('ContentBlockType::image')
  IMAGE = "image",
  // i18n.t('ContentBlockType::sign')
  SIGN = "sign",
  // i18n.t('ContentBlockType::intro')
  INTRO = "intro",
  // i18n.t('ContentBlockType::items')
  ITEMS = "items",
  // i18n.t('ContentBlockType::summary')
  SUMMARY = "summary",
  // i18n.t('ContentBlockType::input')
  INPUT = "input",
  // i18n.t('ContentBlockType::address_input')
  ADDRESS_INPUT = "address_input",
  // i18n.t('ContentBlockType::countries_input')
  COUNTRIES_INPUT = "countries_input",
  // i18n.t('ContentBlockType::date_input')
  DATE_INPUT = "date_input",
  // i18n.t('ContentBlockType::dropdown_input')
  DROPDOWN_INPUT = "dropdown_input",
  // i18n.t('ContentBlockType::email_input')
  EMAIL_INPUT = "email_input",
  // i18n.t('ContentBlockType::file_upload_input')
  FILE_UPLOAD_INPUT = "file_upload_input",
  // i18n.t('ContentBlockType::link_input')
  LINK_INPUT = "link_input",
  // i18n.t('ContentBlockType::long_text_input')
  LONG_TEXT_INPUT = "long_text_input",
  // i18n.t('ContentBlockType::number_input')
  NUMBER_INPUT = "number_input",
  // i18n.t('ContentBlockType::phone_number_input')
  PHONE_NUMBER_INPUT = "phone_number_input",
  // i18n.t('ContentBlockType::text_input')
  TEXT_INPUT = "text_input",
  // i18n.t('ContentBlockType::slider_input')
  SLIDER_INPUT = "slider_input",
  // i18n.t('ContentBlockType::submit')
  SUBMIT = "submit",
  // i18n.t('ContentBlockType::pdf')
  PDF = "pdf",
  // i18n.t('ContentBlockType::question_and_answer')
  QUESTION_AND_ANSWER = "question_and_answer",
}

export interface ContentBlockBase {
  id?: string;
  position?: number;
  type?: ContentBlockType;
}

export interface TextBlock extends ContentBlockBase {
  type: ContentBlockType.TEXT;
  content?: string;
}

export interface ImageBlock extends ContentBlockBase {
  type: ContentBlockType.IMAGE;
  image_url?: string | null;
}

export interface PDFBlock extends ContentBlockBase {
  type: ContentBlockType.PDF;
  pdf_url?: string | null;
  image_urls?: string[];
}

export interface SignBlock extends ContentBlockBase {
  type: ContentBlockType.SIGN;
  title?: string;
  description?: string;
  action?: string;
}

export interface IntroBlock extends ContentBlockBase {
  type: ContentBlockType.INTRO;
  to?: string;
  from?: string;
}

export enum ItemsView {
  // t('ItemsView::table')
  TABLE = "table",
  // t('ItemsView::card')
  CARD = "card",
}

export enum ItemsSelectionType {
  SINGLE = "single",
  MULTI = "multi",
}

export enum ItemsSelectionRequirementType {
  REQUIRED = "required",
  OPTIONAL = "optional",
}

export interface ItemBlock {
  id?: string;
  cover?: string;
  title?: string;
  description?: string;
  amount?: number;
  quantity?: number;
  tax_rate?: number;
  tax_code?: string;
  discount?: number;
  selected?: boolean;
  quantity_adjustment_allowed?: boolean;
}

export interface ItemsBlock extends ContentBlockBase {
  type: ContentBlockType.ITEMS;
  title?: string;
  view?: ItemsView;
  contact_selection_enabled?: boolean;
  selection_type?: ItemsSelectionType;
  selection_requirement_type?: ItemsSelectionRequirementType;
  items: ItemBlock[];
}

export interface SummaryBlock extends ContentBlockBase {
  type: ContentBlockType.SUMMARY;
  title?: string;
}

export interface InputBlockBase extends ContentBlockBase {
  name?: string;
  description?: string;
  placeholder?: string;
  required?: boolean;
}

export interface AddressInputBlock extends InputBlockBase {
  type: ContentBlockType.ADDRESS_INPUT;
  country_options: { label: string; value: string }[];
}

export interface CountriesInputBlock extends InputBlockBase {
  type: ContentBlockType.COUNTRIES_INPUT;
  country_options?: { label: string; value: string }[];
}

export interface DateInputBlock extends InputBlockBase {
  type: ContentBlockType.DATE_INPUT;
}

export interface DropdownInputBlock extends InputBlockBase {
  type: ContentBlockType.DROPDOWN_INPUT;
  options?: string[];
  allow_multiple_selection?: boolean;
}

export interface EmailInputBlock extends InputBlockBase {
  type: ContentBlockType.EMAIL_INPUT;
}

export interface FileUploadInputBlock extends InputBlockBase {
  type: ContentBlockType.FILE_UPLOAD_INPUT;
}

export interface FileUploadInputBlock extends InputBlockBase {
  type: ContentBlockType.FILE_UPLOAD_INPUT;
}

export interface LinkInputBlock extends InputBlockBase {
  type: ContentBlockType.LINK_INPUT;
}

export interface LongTextInputBlock extends InputBlockBase {
  type: ContentBlockType.LONG_TEXT_INPUT;
}

export interface NumberInputBlock extends InputBlockBase {
  type: ContentBlockType.NUMBER_INPUT;
  minimum_limit?: number;
  maximum_limit?: number;
}

export interface PhonenumberInputBlock extends InputBlockBase {
  type: ContentBlockType.PHONE_NUMBER_INPUT;
}

export interface TextInputBlock extends InputBlockBase {
  type: ContentBlockType.TEXT_INPUT;
}

export interface SliderInputBlock extends InputBlockBase {
  type: ContentBlockType.SLIDER_INPUT;
  minimum_limit?: number;
  maximum_limit?: number;
}

export enum QuestionAndAnswerType {
  OPEN = "open",
  LIST = "list",
}

export interface QuestionAndAnswerBlock extends ContentBlockBase {
  type?: ContentBlockType.QUESTION_AND_ANSWER;
  title?: string;
  required?: boolean;
  answer_type?: QuestionAndAnswerType;
  options?: string[];
  allow_multiple_selection?: boolean;
  answer?: string | string[];
}

export interface SubmitBlock extends ContentBlockBase {
  type: ContentBlockType.SUBMIT;
  text?: string;
}

export type ContentBlock =
  | TextBlock
  | ImageBlock
  | PDFBlock
  | SignBlock
  | IntroBlock
  | ItemsBlock
  | SummaryBlock
  | AddressInputBlock
  | CountriesInputBlock
  | DateInputBlock
  | DropdownInputBlock
  | EmailInputBlock
  | FileUploadInputBlock
  | LinkInputBlock
  | LongTextInputBlock
  | NumberInputBlock
  | PhonenumberInputBlock
  | TextInputBlock
  | SliderInputBlock
  | QuestionAndAnswerBlock
  | SubmitBlock;

export interface ContentBlockTemplate {
  id?: string;
  name?: string;
  resource_type?: ContentBlockResource;
  content_block?: ContentBlock;
}

export interface SearchableContentBlock {
  name: string;
  contentBlock: ContentBlockBase;
  contentBlockTemplate?: ContentBlockTemplate;
}

export enum ContractChannelEventType {
  CONTRACT_CREATE = "CONTRACT_CREATE",
  CONTRACT_UPDATE = "CONTRACT_UPDATE",
  CONTRACT_DELETE = "CONTRACT_DELETE",

  CONTENT_BLOCK_CREATE = "CONTENT_BLOCK_CREATE",
  CONTENT_BLOCK_UPDATE = "CONTENT_BLOCK_UPDATE",
  CONTENT_BLOCK_DELETE = "CONTENT_BLOCK_DELETE",
}

export type ContractChannelEvent =
  | ContractChannelCreateContractEvent
  | ContractChannelUpdateContractEvent
  | ContractChannelDeleteContractEvent
  | ContractChannelCreateContentBlockEvent
  | ContractChannelUpdateContentBlockEvent
  | ContractChannelDeleteContentBlockEvent;

export interface ContractChannelEventBase {
  type: ContractChannelEventType;
  data: object;
}

export interface ContractChannelCreateContractEvent
  extends ContractChannelEventBase {
  type: ContractChannelEventType.CONTRACT_CREATE;
  data: { contract: Contract };
}

export interface ContractChannelUpdateContractEvent
  extends ContractChannelEventBase {
  type: ContractChannelEventType.CONTRACT_UPDATE;
  data: { contract: Contract };
}

export interface ContractChannelDeleteContractEvent
  extends ContractChannelEventBase {
  type: ContractChannelEventType.CONTRACT_DELETE;
  data: { contract_id: string };
}

export interface ContractChannelCreateContentBlockEvent
  extends ContractChannelEventBase {
  type: ContractChannelEventType.CONTENT_BLOCK_CREATE;
  data: { content_block: ContentBlock };
}

export interface ContractChannelUpdateContentBlockEvent
  extends ContractChannelEventBase {
  type: ContractChannelEventType.CONTENT_BLOCK_UPDATE;
  data: { content_block: ContentBlock };
}

export interface ContractChannelDeleteContentBlockEvent
  extends ContractChannelEventBase {
  type: ContractChannelEventType.CONTENT_BLOCK_DELETE;
  data: { content_block_id: string };
}

export interface SignatureResultBase {
  full_name: string;
}

export enum SignatureType {
  TYPED = "typed",
  DRAW = "draw",
  UPLOAD = "upload",
}

export interface TypeSignatureResult extends SignatureResultBase {
  type: SignatureType.TYPED;
}
export interface DrawSignatureResult extends SignatureResultBase {
  type: SignatureType.DRAW;
  base64: string;
}

export interface UploadSignatureResult extends SignatureResultBase {
  type: SignatureType.UPLOAD;
  signature: File;
}

export type SignatureResult =
  | TypeSignatureResult
  | DrawSignatureResult
  | UploadSignatureResult;

export interface Signature {
  id?: string;
  key?: string;
  type?: SignatureType;
  full_name?: string;
  ip_address?: string;
  signature_url?: string;
  signee?: Contact;
  signee_id?: string;
  signed_at?: string;
  created_at?: string;
  updated_at?: string;
}

export interface ContentBlockVariables {
  [key: string]: any;
}

export interface SendContractEmailParams {
  to: string[];
  subject: string;
  body: string;
  test_email?: boolean;
}

export interface VariableOption {
  name: string;
  label: string;
  value: string;
  options?: VariableOption[];
}

export enum ProposalStatus {
  // i18n.t('ProposalStatus::draft')
  DRAFT = "draft",
  // i18n.t('ProposalStatus::pending')
  PENDING = "pending",
  // i18n.t('ProposalStatus::accepted')
  ACCEPTED = "accepted",
  // i18n.t('ProposalStatus::cancelled')
  CANCELLED = "cancelled",
  // i18n.t('ProposalStatus::declined')
  DECLINED = "declined",
}

export interface Proposal {
  id?: string;
  name?: string;
  status?: ProposalStatus;
  expires_on?: string | Date;
  currency?: string;
  workspace_id?: string;
  contact_id?: string;
  contact?: Contact;
  project_id?: string;
  project?: Project;
  deal_id?: string;
  deal?: Deal;
  signees?: Contact[];
  signee_ids?: string[];
  created_at?: string;
  content_blocks?: ContentBlock[];
  signatures?: Signature[];
}

export interface SendProposalEmailParams {
  to: string[];
  subject: string;
  body: string;
  test_email?: boolean;
}

export enum ProposalChannelEventType {
  PROPOSAL_CREATE = "PROPOSAL_CREATE",
  PROPOSAL_UPDATE = "PROPOSAL_UPDATE",
  PROPOSAL_DELETE = "PROPOSAL_DELETE",

  CONTENT_BLOCK_CREATE = "CONTENT_BLOCK_CREATE",
  CONTENT_BLOCK_UPDATE = "CONTENT_BLOCK_UPDATE",
  CONTENT_BLOCK_DELETE = "CONTENT_BLOCK_DELETE",
}

export type ProposalChannelEvent =
  | ProposalChannelCreateProposalEvent
  | ProposalChannelUpdateProposalEvent
  | ProposalChannelDeleteProposalEvent
  | ProposalChannelCreateContentBlockEvent
  | ProposalChannelUpdateContentBlockEvent
  | ProposalChannelDeleteContentBlockEvent;

export interface ProposalChannelEventBase {
  type: ProposalChannelEventType;
  data: object;
}

export interface ProposalChannelCreateProposalEvent
  extends ProposalChannelEventBase {
  type: ProposalChannelEventType.PROPOSAL_CREATE;
  data: { proposal: Proposal };
}

export interface ProposalChannelUpdateProposalEvent
  extends ProposalChannelEventBase {
  type: ProposalChannelEventType.PROPOSAL_UPDATE;
  data: { proposal: Proposal };
}

export interface ProposalChannelDeleteProposalEvent
  extends ProposalChannelEventBase {
  type: ProposalChannelEventType.PROPOSAL_DELETE;
  data: { proposal_id: string };
}

export interface ProposalChannelCreateContentBlockEvent
  extends ProposalChannelEventBase {
  type: ProposalChannelEventType.CONTENT_BLOCK_CREATE;
  data: { content_block: ContentBlock };
}

export interface ProposalChannelUpdateContentBlockEvent
  extends ProposalChannelEventBase {
  type: ProposalChannelEventType.CONTENT_BLOCK_UPDATE;
  data: { content_block: ContentBlock };
}

export interface ProposalChannelDeleteContentBlockEvent
  extends ProposalChannelEventBase {
  type: ProposalChannelEventType.CONTENT_BLOCK_DELETE;
  data: { content_block_id: string };
}

export interface CurrencyOption {
  label: string;
  value: string;
  symbol: string;
}

export enum PaymentMethodType {
  CREDIT_CARD = "card",
  SEPA = "sepa_debit",
}

export enum FormStatus {
  // i18n.t('FormStatus::draft')
  DRAFT = "draft",
  // i18n.t('FormStatus::published')
  PUBLISHED = "published",
  // i18n.t('FormStatus::archived')
  ARCHIVED = "archived",
}

export interface Form {
  id?: string;
  name?: string;
  currency?: string;
  activation_date?: string | Date | any;
  expiration_date?: string | Date | any;
  target_email?: string;
  url?: string;
  embed_url?: string;
  redirect_url?: string;
  recaptcha_enabled?: boolean;
  recaptcha_secret?: string;
  response_limit?: number;
  workspace_id?: string;
  responses_count?: number;
  content_blocks?: ContentBlock[];
  task_create_enabled?: boolean;
  board_id?: string;
  board_list_id?: string;
  task_assignee_ids?: string[];
  created_at?: string;
  submittable?: boolean;
  enabled?: boolean;
}

export enum FormChannelEventType {
  FORM_CREATE = "FORM_CREATE",
  FORM_UPDATE = "FORM_UPDATE",
  FORM_DELETE = "FORM_DELETE",

  CONTENT_BLOCK_CREATE = "CONTENT_BLOCK_CREATE",
  CONTENT_BLOCK_UPDATE = "CONTENT_BLOCK_UPDATE",
  CONTENT_BLOCK_DELETE = "CONTENT_BLOCK_DELETE",

  FORM_RESPONSE_CREATE = "FORM_RESPONSE_CREATE",
  FORM_RESPONSE_UPDATE = "FORM_RESPONSE_UPDATE",
  FORM_RESPONSE_DELETE = "FORM_RESPONSE_DELETE",
}

export type FormChannelEvent =
  | FormChannelCreateFormEvent
  | FormChannelUpdateFormEvent
  | FormChannelDeleteFormEvent
  | FormChannelCreateContentBlockEvent
  | FormChannelUpdateContentBlockEvent
  | FormChannelDeleteContentBlockEvent
  | FormChannelCreateFormResponseEvent
  | FormChannelUpdateFormResponseEvent
  | FormChannelDeleteFormResponseEvent;

export interface FormChannelEventBase {
  type: FormChannelEventType;
  data: object;
}

export interface FormChannelCreateFormEvent extends FormChannelEventBase {
  type: FormChannelEventType.FORM_CREATE;
  data: { form: Form };
}

export interface FormChannelUpdateFormEvent extends FormChannelEventBase {
  type: FormChannelEventType.FORM_UPDATE;
  data: { form: Form };
}

export interface FormChannelDeleteFormEvent extends FormChannelEventBase {
  type: FormChannelEventType.FORM_DELETE;
  data: { form_id: string };
}

export interface FormChannelCreateContentBlockEvent
  extends FormChannelEventBase {
  type: FormChannelEventType.CONTENT_BLOCK_CREATE;
  data: { content_block: ContentBlock };
}

export interface FormChannelUpdateContentBlockEvent
  extends FormChannelEventBase {
  type: FormChannelEventType.CONTENT_BLOCK_UPDATE;
  data: { content_block: ContentBlock };
}

export interface FormChannelDeleteContentBlockEvent
  extends FormChannelEventBase {
  type: FormChannelEventType.CONTENT_BLOCK_DELETE;
  data: { content_block_id: string };
}

export interface FormChannelCreateFormResponseEvent {
  type: FormChannelEventType.FORM_RESPONSE_CREATE;
  data: { form_response: FormResponse };
}
export interface FormChannelUpdateFormResponseEvent {
  type: FormChannelEventType.FORM_RESPONSE_UPDATE;
  data: { form_response: FormResponse };
}
export interface FormChannelDeleteFormResponseEvent {
  type: FormChannelEventType.FORM_RESPONSE_DELETE;
  data: { form_response_id: string };
}

export interface FormResponse {
  id?: string;
  data?: { [key: string]: any };
  form_id?: string;
  form?: Form;
  viewed_at?: string | Date;
  created_at: string;
}

export interface BlobResponse {
  filename: string;
  blob: Blob;
}

export enum SpreadsheetExportType {
  // i18n.t('SpreadsheetExportType::xlsx')
  EXCEL = "xlsx",
  // i18n.t('SpreadsheetExportType::csv')
  CSV = "csv",
  // i18n.t('SpreadsheetExportType::ods')
  ODS = "ods",
}

export enum TimeTrackingViewReportMode {
  // t('TimeTrackingViewReportMode::week')
  WEEK = "week",
  // t('TimeTrackingViewReportMode::month')
  MONTH = "month",
  // t('TimeTrackingViewReportMode::quarter')
  QUARTER = "quarter",
  // t('TimeTrackingViewReportMode::year')
  YEAR = "year",
}

export enum TimeTrackingViewReportType {
  // t('TimeTrackingViewReportType::contact')
  CLIENT = "contact",
  // t('TimeTrackingViewReportType::project')
  PROJECT = "project",
  // t('TimeTrackingViewReportType::work_type')
  WORK_TYPE = "work_type",
  // t('TimeTrackingViewReportType::project_team')
  PROJECT_TEAM = "project_team",
  // t('TimeTrackingViewReportType::team')
  TEAM = "team",
}

export interface TimeTrackingReportDataRowBase {
  name: string;
  billable_hours: number;
  unbillable_hours: number;
  hours: number;
  cost_amount: number;
  billable_amount: number;
  net_amount: number;
}

export interface ContactTimeTrackingReportDataRow
  extends TimeTrackingReportDataRowBase {
  contact_id?: string;
}
export interface ProjectTimeTrackingReportDataRow
  extends TimeTrackingReportDataRowBase {
  project_id?: string;
  contact_id?: string;
  contact_name?: string;
  budget?: string | number;
}

export interface WorkTypeTimeTrackingReportDataRow
  extends TimeTrackingReportDataRowBase {
  work_type_id?: string;
}

export interface ProjectTeamTimeTrackingReportDataRow
  extends TimeTrackingReportDataRowBase {
  project_id?: string;
  contact_id?: string;
  contact_name?: string;
  user_id?: string;
  user: string;
}

export interface TeamTimeTrackingReportDataRow
  extends TimeTrackingReportDataRowBase {}

export type TimeTrackingReportDataRow =
  | ContactTimeTrackingReportDataRow
  | ProjectTimeTrackingReportDataRow
  | WorkTypeTimeTrackingReportDataRow
  | ProjectTeamTimeTrackingReportDataRow[]
  | TeamTimeTrackingReportDataRow;

export interface TimeTrackingReportDataBase {
  billable_hours: number;
  unbillable_hours: number;
  hours: number;
  billable_cost_amount: number;
  billable_amount: number;
  unbillable_cost_amount: number;
  unbillable_amount: number;
  uninvoiced_amount: number;
  net_amount: number;
}

export interface ContactTimeTrackingReportData
  extends TimeTrackingReportDataBase {
  type: TimeTrackingViewReportType.CLIENT;
  entries: ContactTimeTrackingReportDataRow[];
}
export interface ProjectTimeTrackingReportData
  extends TimeTrackingReportDataBase {
  type: TimeTrackingViewReportType.PROJECT;
  entries: ProjectTimeTrackingReportDataRow[];
  budget?: string | number;
}

export interface WorkTypeTimeTrackingReportData
  extends TimeTrackingReportDataBase {
  type: TimeTrackingViewReportType.WORK_TYPE;
  entries: WorkTypeTimeTrackingReportDataRow[];
}

export interface ProjectTeamTimeTrackingReportData
  extends TimeTrackingReportDataBase {
  type: TimeTrackingViewReportType.PROJECT_TEAM;
  entries: Array<any[]>;
}

export interface TeamTimeTrackingReportData extends TimeTrackingReportDataBase {
  type: TimeTrackingViewReportType.TEAM;
  entries: TeamTimeTrackingReportDataRow[];
}

export type TimeTrackingReportData =
  | ContactTimeTrackingReportData
  | ProjectTimeTrackingReportData
  | WorkTypeTimeTrackingReportData
  | ProjectTeamTimeTrackingReportData
  | TeamTimeTrackingReportData;

export enum NavigationMenuListType {
  MAIN = "main",
  CHILD = "child",
}

export enum NavigationMenuItemPage {
  // i18n.t('NavigationMenuItemPage::dashboard')
  DASHBOARD = "dashboard",
  // i18n.t('NavigationMenuItemPage::calendar')
  CALENDAR = "calendar",
  // i18n.t('NavigationMenuItemPage::tasks')
  TASKS = "tasks",
  // i18n.t('NavigationMenuItemPage::contacts')
  CONTACTS = "contacts",
  // i18n.t('NavigationMenuItemPage::sequences')
  SEQUENCES = "sequences",
  // i18n.t('NavigationMenuItemPage::deals')
  DEALS = "deals",
  // i18n.t('NavigationMenuItemPage::projects')
  PROJECTS = "projects",
  // i18n.t('NavigationMenuItemPage::track')
  TIME_TRACKING = "track",
  // i18n.t('NavigationMenuItemPage::proposals')
  PROPOSALS = "proposals",
  // i18n.t('NavigationMenuItemPage::invoices')
  INVOICES = "invoices",
  // i18n.t('NavigationMenuItemPage::contracts')
  CONTRACTS = "contracts",
  // i18n.t('NavigationMenuItemPage::expenses')
  EXPENSES = "expenses",
  // i18n.t('NavigationMenuItemPage::bank')
  BANK = "bank",
  // i18n.t('NavigationMenuItemPage::payments')
  PAYMENTS = "payments",
  // i18n.t('NavigationMenuItemPage::accountant')
  ACCOUNTANT = "accountant",
  // i18n.t('NavigationMenuItemPage::products')
  PRODUCTS = "products",
  // i18n.t('NavigationMenuItemPage::forms')
  FORMS = "forms",
  // i18n.t('NavigationMenuItemPage::files')
  FILES = "files",
  // i18n.t('NavigationMenuItemPage::reporting')
  REPORTING = "reporting",
}

export enum NavigationMenuItemType {
  GROUP = "group",
  PAGE = "page",
  LINK = "link",
  DIVIDER = "divider",
}

export interface NavigationMenuItemBase {
  position?: number;
}

export interface GroupNavigationMenuItem extends NavigationMenuItemBase {
  type?: NavigationMenuItemType.GROUP;
  label?: string;
  items?: NavigationMenuItem[];
}
export interface PageNavigationMenuItem extends NavigationMenuItemBase {
  type?: NavigationMenuItemType.PAGE;
  page?: NavigationMenuItemPage;
  home?: boolean;
}

export interface LinkNavigationMenuItem extends NavigationMenuItemBase {
  type?: NavigationMenuItemType.LINK;
  label?: string;
  value?: string;
}

export interface DividerNavigationMenuItem extends NavigationMenuItemBase {
  type?: NavigationMenuItemType.DIVIDER;
}

export type NavigationMenuItem =
  | GroupNavigationMenuItem
  | PageNavigationMenuItem
  | LinkNavigationMenuItem
  | DividerNavigationMenuItem;

export enum LedgerItemPaymentMethod {
  // t('LedgerItemPaymentMethod::cash')
  CASH = "cash",
  // t('LedgerItemPaymentMethod::bank_transfer')
  BANK_TRANSFER = "bank_transfer",
}

export interface LedgerItemPayment {
  id?: string;
  amount?: number;
  payment_method?: "cash" | "bank_transfer";
  currency?: string;
  executed_at?: string | Date;
  details?: string;
  uid?: string;
  ledger_item_id?: string;
}

export interface UnpaidClaim {
  claim_id: string;
  claim_status: string;
  claim_status_explanation: string;
  claim_unpaid_status: number;
  claim_unpaid_status_explanation: string;
  payment_error: boolean;
  payment_tried: boolean;
  payment_url: string;
  calculations: {
    table: {
      amount_due: string;
      amount_due_no_interest: string;
      bailiff_pay: string;
      capped: string;
      excl: string;
      full_tpoay: string;
      receive_extra: number;
      tax: string;
      topay: string;
    };
  };
}

export interface Payment {
  id?: string;
  remittance_information?: string;
  remittance_information_type?: RemittanceInformationType;
  currency?: string;
  amount?: number | string;
  creditor_name?: string;
  creditor_account?: string;
  creditor_account_type?: string;
  notes?: string;
  payment_initiation?: PaymentInitiation;
  payment_initiation_id?: string;
  suggested_execution_date?: string | Date;
  execution_date?: string | Date;
}

export const enum PaymentInitiationStatus {
  // t('PaymentInitiationStatus::draft')
  DRAFT = "draft",
  // t('PaymentInitiationStatus::unsigned')
  UNSIGNED = "unsigned",
  // t('PaymentInitiationStatus::signed')
  SIGNED = "signed",
}

export interface PaymentInitiation {
  id?: string;
  reference?: string;
  status?: PaymentInitiationStatus;
  notes?: string;
  uuid?: string;
  sign_redirect_url?: string;
  execution_date?: string;
  created_at?: string | Date;
  account_id?: string;
  account?: Account;
  payments?: Payment[];
}

export interface PaymentInitiationIntegrationMetadata {
  integration_enabled: boolean;
  payments_activation_requested: boolean;
  payments_activated: boolean;
}

export interface PaymentInitiationForm {
  payment_initiation: PaymentInitiation;
  payment_initiation_metadata: PaymentInitiationIntegrationMetadata;
}

export interface BizzeyInvoice {
  id?: string;
  invoice_id?: string;
  customer_id?: string;
  workspace_Id?: string;
  created_at?: string;
}

export interface Deal {
  id?: string;
  name?: string;
  notes?: string;
  amount?: number;
  currency?: string;
  status?: DealStatus;
  lost_reason?: string;
  contact?: Contact;
  contact_id?: string;
  project?: Project;
  project_id?: string;
  due_on?: string | Date;
  success_rate?: number;
  deal_stage?: DealStage;
  deal_stage_id?: string;
  assignee?: User;
  assignee_id?: string;
  position?: number;
  updated_at?: string | Date;
  created_at?: string | Date;
}

export enum DealStageAction {
  // i18n.t('DealStageAction::event')
  EVENT = "event",
  // i18n.t('DealStageAction::email')
  EMAIL = "email",
  // i18n.t('DealStageAction::call')
  CALL = "call",
  // i18n.t('DealStageAction::appointment')
  APPOINTMENT = "appointment",
  // i18n.t('DealStageAction::task')
  TASK = "task",
}

export enum DealStatus {
  // i18n.t('DealStatus::open')
  OPEN = "open",
  // i18n.t('DealStatus::won')
  WON = "won",
  // i18n.t('DealStatus::lost')
  LOST = "lost",
}

export interface DealStage {
  id?: string;
  name?: string;
  status?: DealStatus;
  success_rate?: number;
  inactivity_alert?: number;
  action?: DealStageAction;
  position?: number;
  created_at?: string | Date;
}

export enum DealViewActionType {
  CALENDAR_EVENT = "calendar_event",
  TASK = "task",
}

export interface DealViewActionBase {
  type: DealViewActionType;
}

export interface DealViewCalendarEventAction extends DealViewActionBase {
  type: DealViewActionType.CALENDAR_EVENT;
  resource: CalendarEvent;
}
export interface DealViewTaskAction extends DealViewActionBase {
  type: DealViewActionType.TASK;
  resource: Task;
}

export enum LedgerItemExportType {
  // i18n.t('LedgerItemExportType::xlsx')
  EXCEL = "xlsx",
  // i18n.t('LedgerItemExportType::csv')
  CSV = "csv",
  // i18n.t('LedgerItemExportType::pdf')
  PDF = "pdf",
  // i18n.t('LedgerItemExportType::ubl')
  UBL = "ubl",
}

export interface ExportLedgerItemParams {
  type: LedgerItemExportType;
  start: string;
  end: string;
}

export enum ExpenseExportType {
  // i18n.t('ExpenseExportType::xlsx')
  EXCEL = "xlsx",
  // i18n.t('ExpenseExportType::csv')
  CSV = "csv",
  // i18n.t('ExpenseExportType::receipt')
  RECEIPT = "receipt",
}

export interface ExportExpenseParams {
  type: ExpenseExportType;
  start: string;
  end: string;
}

export enum ExportTimePeriod {
  // i18n.t('ExportTimePeriod::all')
  ALL = "all",
  // i18n.t('ExportTimePeriod::current_month')
  CURRENT_MONTH = "current_month",
  // i18n.t('ExportTimePeriod::previous_month')
  PREVIOUS_MONTH = "previous_month",
  // i18n.t('ExportTimePeriod::current_quarter')
  CURRENT_QUARTER = "current_quarter",
  // i18n.t('ExportTimePeriod::previous_quarter')
  PREVIOUS_QUARTER = "previous_quarter",
  // i18n.t('ExportTimePeriod::current_year')
  CURRENT_YEAR = "current_year",
  // i18n.t('ExportTimePeriod::previous_year')
  PREVIOUS_YEAR = "previous_year",
  // i18n.t('ExportTimePeriod::custom')
  CUSTOM = "custom",
}

export interface Pagination {
  page: number;
  pageCount: number;
}

export interface WorkType {
  id?: string;
  name?: string;
  color?: string;
  billable?: boolean;
  hourly_rate?: number;
  day_rate?: number;
  multiplier?: number;
  position?: number;
  workspace_id?: string;
}

export enum RateType {
  // i18n.t('RateType::user')
  USER = "user",
  // i18n.t('RateType::work_type')
  WORK_TYPE = "work_type",
  // i18n.t('RateType::fixed')
  FIXED = "fixed",
}

export enum CalendarIntegrationType {
  GOOGLE = "google",
  MICROSOFT = "outlook",
}

export interface CalendarIntegration {
  id?: string;
  type?: CalendarIntegrationType;
  access_token?: string;
  refresh_token?: string;
  config?: object;
}

export interface GoogleCalendar extends CalendarIntegration {
  type: CalendarIntegrationType.GOOGLE;
}

export interface PeppolSearchParams {
  // Generic query term. It queries all fields and the results equal the ones that can be obtained when using the simple search. The query term is split into pieces internally and subwords may be found.
  q?: string;
  // Searches for exact matches in the Peppol participant identifier field (the identifier scheme must be part of the value).
  participant?: string;
  // Searches for partial matches in business entity names.
  name?: string;
  // Searches for exact matches in business entity country codes.
  country?: string;
  // Defines the 0-based index for the result page to be returned. If not provided, the first page is returned. By default only 20 entries are returned and this entry can be used to browse the results. Use this in combination with resultPageCount to define the number of results.
  resultPageIndex?: string | number;
  // Defines the number of items to be returned per page. If not provided 20 entries are returned. Use this in combination with resultPageIndex to define the number of results.
  resultPageCount?: string | number;
}

export interface PeppolParticipant {
  participantID: {
    scheme: string;
    value: string;
  };
  docTypes: Array<{
    scheme: string;
    value: string;
  }>;
  entities: Array<{
    name: Array<{
      name: string;
    }>;
    countryCode: string;
    websites?: string[];
    additionalInfo?: string;
    identifiers?: Array<{
      scheme: string;
      value: string;
    }>;
    regDate?: string | Date;
  }>;
}

export interface PeppolSearchResult {
  version: string;
  "total-result-count": number;
  "used-result-count": number;
  "result-page-index": number;
  "result-page-count": number;
  "first-result-index": number;
  "last-result-index": number;
  "query-terms": string;
  "creation-dt": string;
  matches: PeppolParticipant[];
}

export enum ContactViewActionType {
  CALENDAR_EVENT = "calendar_event",
  TASK = "task",
}

export interface ContactViewActionBase {
  type: ContactViewActionType;
}

export interface ContactViewCalendarEventAction extends ContactViewActionBase {
  type: ContactViewActionType.CALENDAR_EVENT;
  resource: CalendarEvent;
}
export interface ContactViewTaskAction extends ContactViewActionBase {
  type: ContactViewActionType.TASK;
  resource: Task;
}

export interface FinancialYear {
  id?: string;
  name?: string;
  start_date?: string;
  end_date?: string;
}

export interface Account {
  id?: string;
  name?: string;
  reference?: string;
  currency?: string;
  financial_institution?: string;
  balance?: number;
  reauthorize_at?: string | Date;
}

export interface Transaction {
  id?: string;
  counterpart_name?: string;
  counterpart_account_number?: string;
  description?: string;
  additional_information?: string;
  amount?: string;
  linked_amount?: string;
  currency?: string;
  remittance_information?: string;
  remittance_information_type?: string;
  provider?: string;
  uid?: string;
  execution_time?: string;
  notes?: string;
  verified?: boolean;
  manually_verified?: boolean;
  payment_ids?: string[];
  ledger_item_ids?: string[];
  expense_ids?: string[];
  account_id?: string;
  account?: Account;
}

export interface Playbook {
  id?: string;
  name?: string;
  workspace_id?: string;
  content_blocks?: ContentBlock[];
  created_at?: string;
}

export enum PlaybookableType {
  CONTACT = "Contact",
  TASK = "Task",
}

export interface PlaybookSubmission {
  id?: string;
  playbook_id?: string;
  playbookable_id?: string;
  playbookable_type?: PlaybookableType;
  answers?: PlaybookSubmissionAnswer[];
}

export interface PlaybookSubmissionAnswer {
  id?: string;
  notes?: string;
  selected_answers?: string[];
  question_id?: string;
  playbook_submission_id?: string;
}

export enum PlaybookChannelEventType {
  PLAYBOOK_CREATE = "PLAYBOOK_CREATE",
  PLAYBOOK_UPDATE = "PLAYBOOK_UPDATE",
  PLAYBOOK_DELETE = "PLAYBOOK_DELETE",

  CONTENT_BLOCK_CREATE = "CONTENT_BLOCK_CREATE",
  CONTENT_BLOCK_UPDATE = "CONTENT_BLOCK_UPDATE",
  CONTENT_BLOCK_DELETE = "CONTENT_BLOCK_DELETE",
}

export type PlaybookChannelEvent =
  | PlaybookChannelCreatePlaybookEvent
  | PlaybookChannelUpdatePlaybookEvent
  | PlaybookChannelDeletePlaybookEvent
  | PlaybookChannelCreateContentBlockEvent
  | PlaybookChannelUpdateContentBlockEvent
  | PlaybookChannelDeleteContentBlockEvent;

export interface PlaybookChannelEventBase {
  type: PlaybookChannelEventType;
  data: object;
}

export interface PlaybookChannelCreatePlaybookEvent
  extends PlaybookChannelEventBase {
  type: PlaybookChannelEventType.PLAYBOOK_CREATE;
  data: { playbook: Playbook };
}

export interface PlaybookChannelUpdatePlaybookEvent
  extends PlaybookChannelEventBase {
  type: PlaybookChannelEventType.PLAYBOOK_UPDATE;
  data: { playbook: Playbook };
}

export interface PlaybookChannelDeletePlaybookEvent
  extends PlaybookChannelEventBase {
  type: PlaybookChannelEventType.PLAYBOOK_DELETE;
  data: { playbook_id: string };
}

export interface PlaybookChannelCreateContentBlockEvent
  extends PlaybookChannelEventBase {
  type: PlaybookChannelEventType.CONTENT_BLOCK_CREATE;
  data: { content_block: ContentBlock };
}

export interface PlaybookChannelUpdateContentBlockEvent
  extends PlaybookChannelEventBase {
  type: PlaybookChannelEventType.CONTENT_BLOCK_UPDATE;
  data: { content_block: ContentBlock };
}

export interface PlaybookChannelDeleteContentBlockEvent
  extends PlaybookChannelEventBase {
  type: PlaybookChannelEventType.CONTENT_BLOCK_DELETE;
  data: { content_block_id: string };
}

export interface UBLExtractionBase {
  type?: "invoice" | "credit_note";
  issue_date?: string;
  currency?: string;
  supplier?: {
    endpoint_scheme?: string;
    endpoint_id?: string;
    party_id?: string;
    name?: string;
    company_id?: string;
    legal_name?: string;
    address?: {
      street?: string;
      city?: string;
      postal?: string;
      country_code?: string;
    };
  };
  customer?: {
    endpoint_scheme?: string;
    endpoint_id?: string;
    party_id?: string;
    name?: string;
    company_id?: string;
    legal_name?: string;
    address?: {
      street?: string;
      city?: string;
      postal?: string;
      country_code?: string;
    };
  };
  payment_details?: {
    id?: string;
    account_id?: string;
    account_name?: string;
    account_swift?: string;
    terms?: string;
  };
  total_exclusive?: string | number;
  total_inclusive?: string | number;
  line_items?: string | number;
  attachments?: string[];
}

export interface UBLInvoiceExtractionData extends UBLExtractionBase {
  type: "invoice";
  due_date?: string;
}

export interface UBLCreditnoteExtractionData extends UBLExtractionBase {
  type: "credit_note";
}

export type UBLData = UBLInvoiceExtractionData | UBLCreditnoteExtractionData;

export enum SettingsEmailTemplate {
  // i18n.t(`EmailTemplate::order_form`)
  ORDER_FORM = "order_form",
  // i18n.t(`EmailTemplate::delivery_note`)
  DELIVERY_NOTE = "delivery_note",
  // i18n.t(`EmailTemplate::quote`)
  QUOTE = "quote",
  // i18n.t(`EmailTemplate::pro_forma_invoice`)
  PRO_FORMA_INVOICE = "pro_forma_invoice",
  // i18n.t(`EmailTemplate::invoice`)
  INVOICE = "invoice",
  // i18n.t(`EmailTemplate::invoice_reminder`)
  INVOICE_REMINDER = "invoice_reminder",
  // i18n.t(`EmailTemplate::invoice_paid`)
  INVOICE_PAID = "invoice_paid",
  // i18n.t(`EmailTemplate::credit_note`)
  CREDIT_NOTE = "credit_note",
  // i18n.t(`EmailTemplate::proposal`)
  PROPOSAL = "proposal",
  // i18n.t(`EmailTemplate::contract`)
  CONTRACT = "contract",
}

export interface Transfer {
  id?: string;
  name?: string;
  expires_at?: string;
  workspace_id?: string;
  created_at?: string;
  files: ActiveStorage.Blob[];
}

export interface Email {
  id?: string;
  from?: EmailHeader;
  to?: EmailHeader[];
  cc?: EmailHeader[];
  subject?: string;
  body?: string;
  raw_text?: string;
  raw_html?: string;
  raw_body?: string;
  attachments?: any[];
  created_at?: string;
}

export interface EmailHeader {
  token?: string;
  host?: string;
  email?: string;
  full?: string;
  name?: string;
}

export enum ActivityType {
  CREATION = "creation",
  NOTE = "note",
  EMAIL = "email",
  CALENDAR_EVENT = "calendar_event",
  TASK = "task",
  OFFER_SIGNED = "offer_signed",
  INVOICE_PAID = "invoice_paid",
  LEDGER_ITEM_SEEN = "ledger_item_seen",
  CALL = "call",
  ENROLL_SEQUENCE = "enroll_sequence",
  UNENROLL_SEQUENCE = "unenroll_sequence",
}

export enum ActivityTrackableType {
  CLIENT = "Contact",
  PROJECT = "Project",
  DEAL = "Deal",
}

interface ActivityBase {
  id?: string;
  type?: ActivityType;
  trackable_type?: ActivityTrackableType;
  trackable_id?: string;
  trackable?: any;
  model_type?: string;
  model_id?: string;
  model?: any;
  owner_type?: string;
  owner_id?: string;
  owner?: any;
  recipient_type?: string;
  recipient_id?: string;
  recipient?: any;
  data: Object;
  workspace_id?: string;
  created_at?: string;
}

interface CreatedActivity extends ActivityBase {
  type: ActivityType.CREATION;
  data: {};
}

interface NoteActivity extends ActivityBase {
  type: ActivityType.NOTE;
  data: { note: string };
}

interface EmailActivity extends ActivityBase {
  type: ActivityType.EMAIL;
  model: Email;
  model_type: "Email";
}

interface CalendarEventActivity extends ActivityBase {
  type: ActivityType.CALENDAR_EVENT;
  model: CalendarEvent;
  model_type: "CalendarEvent";
}

interface TaskActivity extends ActivityBase {
  type: ActivityType.TASK;
  model: Task;
  model_type: "Task";
}

interface InvoicePaidActivity extends ActivityBase {
  type: ActivityType.INVOICE_PAID;
  model: LedgerItem;
  model_type: "LedgerItem";
}

interface LedgerItemSeenActivity extends ActivityBase {
  type: ActivityType.LEDGER_ITEM_SEEN;
  model: LedgerItem;
  model_type: "LedgerItem";
}

interface OfferSignedActivity extends ActivityBase {
  type: ActivityType.OFFER_SIGNED;
  model: LedgerItem;
  model_type: "LedgerItem";
}

interface CallActivity extends ActivityBase {
  type: ActivityType.CALL;
  model: Call;
  model_type: "Call";
}

interface SequenceEnrollmentActivity extends ActivityBase {
  type: ActivityType.ENROLL_SEQUENCE;
  model: Sequence;
  model_type: "Sequence";
}

interface SequenceUnenrollmentActivity extends ActivityBase {
  type: ActivityType.UNENROLL_SEQUENCE;
  model: Sequence;
  model_type: "Sequence";
}

export type Activity =
  | CreatedActivity
  | NoteActivity
  | EmailActivity
  | CalendarEventActivity
  | TaskActivity
  | InvoicePaidActivity
  | LedgerItemSeenActivity
  | OfferSignedActivity
  | CallActivity
  | SequenceEnrollmentActivity
  | SequenceUnenrollmentActivity;

export interface QRCData {
  name: string;
  iban: string;
  remittanceInformationType: RemittanceInformationType;
  remittanceInformation: string;
  amount: number;
  currency: string;
}

export interface TaskTimeTrackingReportDataBase {}

export interface TaskSummaryTimeTrackingReportDataRow
  extends TaskTimeTrackingReportDataBase {
  user_id?: string;
  name: string;
  time_spent?: string;
}

export interface TaskTimesheetTimeTrackingReportDataRow
  extends TaskTimeTrackingReportDataBase {
  user_id?: string;
  user: string;
}

export type TaskTimeTrackingReportDataRow =
  | TaskSummaryTimeTrackingReportDataRow
  | TimeEntry[];

export enum TaskTimeTrackingViewReportType {
  // t('TaskTimeTrackingViewReportType::summary')
  SUMMARY = "summary",
  // t('TaskTimeTrackingViewReportType::timesheet')
  TIMESHEET = "timesheet",
}

export interface TaskSummaryTimeTrackingReportData
  extends TaskTimeTrackingReportDataBase {
  type: TaskTimeTrackingViewReportType.SUMMARY;
  time_estimated: string;
  time_spent: string;
  entries: TaskSummaryTimeTrackingReportDataRow[];
}
export interface TaskTimesheetTimeTrackingReportData
  extends TaskTimeTrackingReportDataBase {
  type: TaskTimeTrackingViewReportType.TIMESHEET;
  time_estimated: string;
  time_spent: string;
  entries: TimeEntry[];
}

export type TaskTimeTrackingReportData =
  | TaskSummaryTimeTrackingReportData
  | TaskTimesheetTimeTrackingReportData;

export interface VATResponse {
  name?: string;
  vat_number?: string;
  company_type?: string;
  street?: string;
  street_number?: string;
  postal?: string;
  city?: string;
  country?: string;
  full_address?: string;
  errors?: any;
}

export interface Document {
  id?: string;
  name?: string;
  description?: string;
  date?: string;
  booked_on?: string;
  created_at?: string;
  attachment?: any;
  attachment_file_name?: string;
  attachment_content_type?: string;
  attachment_file_size?: number;
  attachment_url?: string | null;
  attachment_download_url?: string | null;
  tags?: DocumentTag[];
  tag_ids?: string[];
}

export interface DocumentTag {
  id?: string;
  name?: string;
  type?: string;
}

export interface ExportDocumentParams {
  start: string;
  end: string;
}

export enum FollowUpActionType {
  // i18n.t('FollowUpActionType::call')
  CALL = "call",
  // i18n.t('FollowUpActionType::email')
  EMAIL = "email",
  // i18n.t('FollowUpActionType::todo')
  TODO = "todo",
}

export interface PlannerItem {
  user: User;
  dates: PlannerDate[];
}

export interface PlannerDate {
  day: Date | string;
  items: PlannerDateItem[];
  start_time: string;
  end_time: string;
  break_enabled: boolean;
  break_start_time: string;
  break_end_time: string;
  availability: number;
}

export type PlannerDateItem = CalendarEventPlannerItem | PlannedTaskPlannerItem;

interface BasePlannerEventItem {
  id: string;
  title?: string;
  type: "calendar_event" | "planned_task";
  start_at: string | Date;
  end_at: string | Date;
  duration: number;
}

interface CalendarEventPlannerItem extends BasePlannerEventItem {
  type: "calendar_event";
}

interface PlannedTaskPlannerItem extends BasePlannerEventItem {
  type: "planned_task";
  task_id: string;
}

export type PlannerData = PlannerItem[];

export interface PlannedTask {
  id?: string;
  start_at?: string | Date;
  end_at?: string | Date;
  duration?: number;
  task_id?: string;
  user?: User;
  user_id?: string;
  task?: Task;
  created_at?: string | Date;
}

export interface ActiveStorageBlob extends ActiveStorage.Blob {
  id: string;
}

export enum PushNotificationDataType {
  CALL = "call",
}

export interface PushNotificationDataBase {
  type: PushNotificationDataType;
}

export interface CallPushNotificationPayload extends PushNotificationDataBase {
  type: PushNotificationDataType.CALL;
  telephone_number: string;
}

export type PushNotificationData = CallPushNotificationPayload;

export enum CallOutcome {
  // i18n.t('CallOutcome::busy')
  BUSY = "busy",
  // i18n.t('CallOutcome::connected')
  CONNECTED = "connected",
  // i18n.t('CallOutcome::left_live_message')
  LEFT_LIVE_MESSAGE = "left_live_message",
  // i18n.t('CallOutcome::left_voicemail')
  LEFT_VOICEMAIL = "left_voicemail",
  // i18n.t('CallOutcome::no_answer')
  NO_ANSWER = "no_answer",
  // i18n.t('CallOutcome::wrong_number')
  WRONG_NUMBER = "wrong_number",
}

export enum CallDirection {
  // i18n.t('CallDirection::inbound')
  INBOUND = "inbound",
  // i18n.t('CallDirection::outbound')
  OUTBOUND = "outbound",
}

export interface Call {
  id?: string;
  outcome?: CallOutcome;
  direction?: CallDirection;
  datetime?: string | Date;
  notes?: string;
  contact_id?: string;
  deal_id?: string;
  created_at?: string | Date;
}

export interface EmailTemplate {
  id?: string;
  name?: string;
  subject?: string;
  body?: string;
  attachment_ids?: string[];
  attachments?: ActiveStorageBlob[];
  user_id?: string;
  created_at?: string;
}

export interface SequenceMetadata {
  scheduled?: number;
  active?: number;
  paused?: number;
  finished?: number;
}

export interface Sequence {
  id?: string;
  name?: string;
  active?: boolean;
  business_days_only?: boolean;
  steps?: SequenceStep[];
}

export enum SequenceStepType {
  AUTOMATED_EMAIL = "automated_email",
  MANUAL_EMAIL = "manual_email",
  CALL = "call",
  TASK = "task",
}

export interface SequenceStepBase {
  id?: string;
  type?: SequenceStepType;
  delay?: number;
  sequence_id?: string;
  position?: number;
}

export interface SequenceStepEmail {
  id?: string;
  active?: boolean;
  subject?: string;
  body?: string;
  send_counter?: number;
  open_counter?: number;
  click_counter?: number;
}

export interface AutomatedEmailSequenceStep extends SequenceStepBase {
  type: SequenceStepType.AUTOMATED_EMAIL;
  emails?: SequenceStepEmail[];
}

export interface ManualEmailSequenceStep extends SequenceStepBase {
  type: SequenceStepType.MANUAL_EMAIL;
  name?: string;
  description?: string;
  created_counter?: number;
  completed_counter?: number;
}

export interface CallSequenceStep extends SequenceStepBase {
  type: SequenceStepType.CALL;
  name?: string;
  description?: string;
  created_counter?: number;
  completed_counter?: number;
  playbook_id?: string;
}

export interface TaskSequenceStep extends SequenceStepBase {
  type: SequenceStepType.TASK;
  name?: string;
  description?: string;
  created_counter?: number;
  completed_counter?: number;
}

export type SequenceStep =
  | AutomatedEmailSequenceStep
  | ManualEmailSequenceStep
  | CallSequenceStep
  | TaskSequenceStep;

export interface SequenceStepTypeInfo {
  type: SequenceStepType;
  icon: IconTypes;
  title: string;
  description: string;
  color: string;
  backgroundColor: string;
}

export enum SequenceChannelEventType {
  SEQUENCE_CREATE = "SEQUENCE_CREATE",
  SEQUENCE_UPDATE = "SEQUENCE_UPDATE",
  SEQUENCE_DELETE = "SEQUENCE_DELETE",

  SEQUENCE_STEP_CREATE = "SEQUENCE_STEP_CREATE",
  SEQUENCE_STEP_UPDATE = "SEQUENCE_STEP_UPDATE",
  SEQUENCE_STEP_DELETE = "SEQUENCE_STEP_DELETE",
}

export type SequenceChannelEvent =
  | SequenceChannelCreateSequenceEvent
  | SequenceChannelUpdateSequenceEvent
  | SequenceChannelDeleteSequenceEvent
  | SequenceChannelCreateSequenceStepEvent
  | SequenceChannelUpdateSequenceStepEvent
  | SequenceChannelDeleteSequenceStepEvent;

export interface SequenceChannelEventBase {
  type: SequenceChannelEventType;
  data: object;
}

export interface SequenceChannelCreateSequenceEvent
  extends SequenceChannelEventBase {
  type: SequenceChannelEventType.SEQUENCE_CREATE;
  data: { sequence: Sequence };
}

export interface SequenceChannelUpdateSequenceEvent
  extends SequenceChannelEventBase {
  type: SequenceChannelEventType.SEQUENCE_UPDATE;
  data: { sequence: Sequence };
}

export interface SequenceChannelDeleteSequenceEvent
  extends SequenceChannelEventBase {
  type: SequenceChannelEventType.SEQUENCE_DELETE;
  data: { sequence_id: string };
}

export interface SequenceChannelCreateSequenceStepEvent
  extends SequenceChannelEventBase {
  type: SequenceChannelEventType.SEQUENCE_STEP_CREATE;
  data: { sequence_step: SequenceStep };
}

export interface SequenceChannelUpdateSequenceStepEvent
  extends SequenceChannelEventBase {
  type: SequenceChannelEventType.SEQUENCE_STEP_UPDATE;
  data: { sequence_step: SequenceStep };
}

export interface SequenceChannelDeleteSequenceStepEvent
  extends SequenceChannelEventBase {
  type: SequenceChannelEventType.SEQUENCE_STEP_DELETE;
  data: { sequence_step_id: string };
}

export enum SequenceEnrollmentStatus {
  // i18n.t('SequenceEnrollmentStatus::scheduled')
  SCHEDULED = "scheduled",
  // i18n.t('SequenceEnrollmentStatus::in_progress')
  IN_PROGRESS = "in_progress",
  // i18n.t('SequenceEnrollmentStatus::paused')
  PAUSED = "paused",
  // i18n.t('SequenceEnrollmentStatus::finished')
  FINISHED = "finished",
  // i18n.t('SequenceEnrollmentStatus::error')
  ERROR = "error",
}

export interface SequenceEnrollment {
  id?: string;
  contact?: Contact;
  contact_id?: string;
  status?: SequenceEnrollmentStatus;
  created_at?: string | Date;
  sequence_id?: string;
  step_id?: string;
}
