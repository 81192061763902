import { keyframes } from 'styled-components'

const shimmerAnimation = keyframes`
	0% {
		opacity: .45;
	}

	100% {
		opacity: .9;
	}
`

export default {
	baseTransitionSpeed: '150ms',
	shimmerAnimation: shimmerAnimation,
}