import { CSSProperties } from "styled-components"

enum FontWeight {
	Thin = 100,
	Light = 200,
	Regular = 400,
	Medium = 500,
	SemiBold = 600,
	Bold = 700,
	Black = 800,
}

const titleXXS: CSSProperties = {
	fontSize: 40,
}
const titleExtraLarge: CSSProperties = {
	fontSize: 34,
}
const titleLarge: CSSProperties = {
	fontSize: 28,
}
const titleSemiLarge: CSSProperties = {
	fontSize: 24,
}
const title: CSSProperties = {
	fontSize: 20,
}
const subtitle: CSSProperties = {
	fontSize: 18,
}
const subheading: CSSProperties = {
	fontSize: 16,
}
const body: CSSProperties = {
	fontSize: 14,
}
const caption: CSSProperties = {
	fontSize: 12,
}
const tiny: CSSProperties = {
	fontSize: 10,
}

interface IFontStyles {
	titleXXS: CSSProperties,
	titleXXSMedium: CSSProperties,
	titleXXSBold: CSSProperties,
	titleExtraLarge: CSSProperties,
	titleExtraLargeMedium: CSSProperties,
	titleExtraLargeBold: CSSProperties,
	titleLarge: CSSProperties,
	titleLargeMedium: CSSProperties,
	titleLargeBold: CSSProperties,
	titleSemiLarge: CSSProperties,
	titleSemiLargeMedium: CSSProperties,
	titleSemiLargeBold: CSSProperties,
	title: CSSProperties,
	titleMedium: CSSProperties,
	titleBold: CSSProperties,
	subtitle: CSSProperties,
	subtitleMedium: CSSProperties,
	subtitleBold: CSSProperties,
	subheading: CSSProperties,
	subheadingMedium: CSSProperties,
	subheadingBold: CSSProperties,
	subheadingBlack: CSSProperties,
	body: CSSProperties
	bodyMedium: CSSProperties,
	bodyBold: CSSProperties,
	caption: CSSProperties,
	captionMedium: CSSProperties,
	captionBold: CSSProperties,
	tiny: CSSProperties,
	tinyMedium: CSSProperties,
	tinyBold: CSSProperties,
}

const font: IFontStyles = {
	titleXXS: {
		...titleXXS,
		fontWeight: FontWeight.Regular,
	},
	titleXXSMedium: {
		...titleXXS,
		fontWeight:FontWeight.Medium
	},
	titleXXSBold: {
		...titleXXS,
		fontWeight: FontWeight.Bold
	},
	titleExtraLarge: {
		...titleExtraLarge,
		fontWeight: FontWeight.Regular
	},
	titleExtraLargeMedium: {
		...titleExtraLarge,
		fontWeight: FontWeight.Medium
	},
	titleExtraLargeBold: {
		...titleExtraLarge,
		fontWeight: FontWeight.Medium
	},
	titleLarge: {
		...titleLarge,
		fontWeight: FontWeight.Regular
	},
	titleLargeMedium: {
		...titleLarge,
		fontWeight: FontWeight.Medium,
	},
	titleLargeBold: {
		...titleLarge,
		fontWeight: FontWeight.Bold,
	},
	titleSemiLarge: {
		...titleSemiLarge,
		fontWeight: FontWeight.Regular,
	},
	titleSemiLargeMedium: {
		...titleSemiLarge,
		fontWeight: FontWeight.Medium,
	},
	titleSemiLargeBold: {
		...titleSemiLarge,
		fontWeight: FontWeight.Bold,
	},
	title: {
		...title,
		fontWeight: FontWeight.Regular,
	},
	titleMedium: {
		...title,
		fontWeight: FontWeight.Medium,
	},
	titleBold: {
		...title,
		fontWeight: FontWeight.Bold,
	},
	subtitle: {
		...subtitle,
		fontWeight: FontWeight.Regular,
	},
	subtitleMedium: {
		...subtitle,
		fontWeight: FontWeight.Medium,
	},
	subtitleBold: {
		...subtitle,
		fontWeight: FontWeight.Bold,
	},
	subheading: {
		...subheading,
		fontWeight: FontWeight.Regular,
	},
	subheadingMedium: {
		...subheading,
		fontWeight: FontWeight.Medium,
	},
	subheadingBold: {
		...subheading,
		fontWeight: FontWeight.Bold,
	},
	subheadingBlack: {
		...subheading,
		fontWeight: FontWeight.Black,
	},
	body: {
		...body,
		fontWeight: FontWeight.Regular,
	},
	bodyMedium: {
		...body,
		fontWeight: FontWeight.Medium,
	},
	bodyBold: {
		...body,
		fontWeight: FontWeight.Bold,
	},
	caption: {
		...caption,
		fontWeight: FontWeight.Regular,
	},
	captionMedium: {
		...caption,
		fontWeight: FontWeight.Medium,
	},
	captionBold: {
		...caption,
		fontWeight: FontWeight.Bold,
	},
	tiny: {
		...tiny,
		fontWeight: FontWeight.Regular,
	},
	tinyMedium: {
		...tiny,
		fontWeight: FontWeight.Medium,
	},
	tinyBold: {
		...tiny,
		fontWeight: FontWeight.Bold,
	},
}

export default font
