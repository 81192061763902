export default {
	grayDarkest: '#1e1f2f',
	white: '#fff',
	black: 'black',
	brandSuccess: '#4bc800',
	brandDanger: '#ff4040',
	brandPrimary: '#4389fc',
	brandWarning: '#ffb35a',
	dashboardColor: '#ffb35a',
	spaceColor: 'rgb(54, 97, 154);',
	contactColor: 'rgb(195, 203, 33)',
	clientColor: '#4389fc',
	supplierColor: 'rgb(195, 203, 33)',
	projectColor: '#ff4040',
	timeColor: '#4389fc',
	invoiceColor: '#2acf79',
	expenseColor: '#B388EB',
	productColor: '#BE8A60',
	reportingColor: '#0fb9b1',
	defaultColor: '#244063',
	baseFontColor: '#1d3451',
	border: '#c4cdd5',
	budgetColor: '#0379c9',
	budgetRemainingColor: '#9ab3dd',
	budgetTrail: '#eee',
	link: 'rgb(3,121,201)'
}
