export default {
	x0: '0px',
	x0_25: '2px',
	x0_5: '4px',
	x0_75: '6px',
	x1: '8px',
	x1_25: '10px',
	x1_5: '12px',
	x2: '16px',
	x2_5: '20px',
	x3: '24px',
	x3_5: '28px',
	x4: '32px',
	x5: '40px',
	x6: '48px',
	x7: '56px',
	x8: '64px',
	x9: '72px',
	x10: '80px',
	x11: '88px',
	x12: '96px',
}
