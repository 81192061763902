import variables from './variables'
import font from './font'
import spacing from './spacing'
import color from './color'
import breakpoints from './breakpoints'
import animations from './animations'

const Style = {
	font,
	spacing,
	color,
	breakpoints,
	animations,
	variables,
}
export { Style }
